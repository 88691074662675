<template>
    <ContextualInlineGuide class="mt-10" :section="InlineGuideSection.Skills" />
    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.Skills" />
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { useSkillsStore } from '@/store/skills/store';
    import { InlineGuideSection } from '@/store/inline-guide/types';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';

    const store = useSkillsStore();
</script>
