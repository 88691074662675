<template>
    <v-btn class="text-custom-purple-deep bg-white" variant="outlined" @click="handleLogout">
        Log Out
    </v-btn>
</template>

<script setup lang="ts">
    import { useAuth0 } from '@/auth/auth';
    import { useInlineGuideStore } from '@/store/inline-guide/store';

    const { logout } = useAuth0();

    const store = useInlineGuideStore();

    const handleLogout = () => {
        // Clear the inline guide store local storage.
        // This is not perfect, but it's a quick fix to clear the inline guide store.
        // Ideally, we should clear the storage when the user logs in, but could not detect that change with auth0 sdk.
        store.clearStorage()

        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    }
</script>
