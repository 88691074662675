<template>
    <!--
        At the moment we use story section (InlineGuideSection.Story) as the text from the backend are compatible with this activity
        They talk about uploading CV, jobs, etc, all related to career history which is part of this activity
        If a more grained messaging is needed, consider adding a new inline guide in the api
     -->
    <ContextualInlineGuide
        class="mt-10"
        :only="[InlineGuideActionName.UploadCv, InlineGuideActionName.ReTryUploadCv]"
        :section="InlineGuideSection.Story"
    />
    <!--
        Note: There is no specific guide for this page, so we make the avatar dissapear as soon as the message is gone
        TODO: Make: guide for this page
     -->
    <app-guide-chat-activator v-if="inlineGuideStore.message !== null" />

    <!--    <app-guide-chat-dialog :screen="GuideSupportedScreen.Story" />-->
</template>

<script setup lang="ts">
    import { InlineGuideActionName, InlineGuideSection } from '@/store/inline-guide/types';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';
    import { useInlineGuideStore } from '@/store/inline-guide/store';

    const inlineGuideStore = useInlineGuideStore();
</script>
