<template>
    <AppCard
        class="bg-white border-2-grey-200 px-5 py-3"
        :editable="true"
        :removable="false"
        @edit-click="emit('edit-click', item)"
    >
        <template v-slot:title>
            <v-row class="d-flex mr-5 align-center" no-gutters>
                <v-col
                    :cols="6"
                    class="px-0 text-paragraph-lg font-weight-700"
                    @click="onEnableEditClick"
                >
                    <!--                    {{ isCanvasSkill(item) ? '(c)' : '(i)' }}-->
                    <!--                    {{ item.type }}-->
                    {{ item.description }}
                </v-col>
                <v-col cols="6" class="pa-0 justify-end text-right">
                    <app-label :type="props.item.type" />
                </v-col>
            </v-row>
        </template>
    </AppCard>
</template>

<script setup lang="ts">
    import { useSkillsStore } from '@/store/skills/store';
    import AppCard from '@/components/common/cards/AppCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import type { CanvasSkill } from '@/api/types/canvas/skills';

    const props = defineProps<{
        item: CanvasSkill;
    }>();

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['edit-click', 'delete-click', 'change-type']);

    const store = useSkillsStore();

    const onEnableEditClick = () => {
        store.setEdit(props.item);
    };
</script>

<style scoped lang="scss"></style>
