<template>
    <app-guide-modal-multiple-steps
        v-model="show"
        section-name="Personal Values"
        action-text="Keep going"
        :steps="1"
        :navigate-back="false"
    >
        <template v-slot:title>Keep adding values!</template>

        <div>
            <p>
                This is a tricky activity! Take your time and choose carefully, as you can only hold
                6 CORE values and 6 GROWTH values. It's a process of what matters most, to you now,
                in your working life.
            </p>

            <p>
                Feel free to re-arrange after you’ve added them by dragging and dropping. And if
                you’ve decided a value is no longer important, just remove it.
            </p>
        </div>

        <template v-slot:actions="{ close }">
            <v-spacer></v-spacer>
            <app-button color="primary" variant="flat" @click="close"> Keep going</app-button>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { storeToRefs } from 'pinia';
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const show = ref(false);
    const showOnlyOnce = ref(true);

    const store = storeToRefs(usePersonalValuesStore());
    watch(store.totalImportantTaggedValues, (value) => {
        // only shows once half-way through the activity
        if (value === 6 && showOnlyOnce) {
            show.value = true;
            showOnlyOnce.value = false;
        }
    });
</script>
