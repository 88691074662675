<template>
    <app-page-header
        section-title="Challenge pathway:"
        :title="title"
        @show-tutorial="onShowTutorial"
    ></app-page-header>
    <ChallengePathTutorial v-model="showTutorial" :navigate-back="tutorialNavigateBack" />
</template>

<script setup lang="ts">
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { computed } from 'vue';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';
    import { useTutorial } from '@/store/useTutorial';

    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const store = useSingleChallengePathStore();

    const title = computed(() => {
        return store.challenge?.description;
    });
</script>
