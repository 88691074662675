import { defineStore } from 'pinia';
import type {
    CanvasCurrentChallengeItem,
    CurrentChallengeStoreState,
    EditableCurrentChallenge,
} from '@/store/current-challenges/types';
import {
    fetchCanvasCurrentChallenges,
    updateCanvasCurrentChallenge,
} from '@/services/current-challenges/service';
import { CurrentChallengeState } from '@/api/types/canvas/currentChallenge';
import { useCanvasStore } from '@/store/canvas/store';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { cloneDeep, isEqual } from 'lodash';
import { fetchPredefinedChallenges } from '@/services/predefined-challenges/service';
import type { PredefinedChallenge } from '@/api/types/predefinedChallenge';

export function makeEditableChallenge(
    challenge: CanvasCurrentChallengeItem,
): EditableCurrentChallenge {
    return {
        id: challenge.id,
        description: challenge.description,
        actions: challenge.actions,
        state: challenge.state,
        is_active: challenge.is_active,
    };
}

export function makeEditableChallengeFromPredefined(
    predefinedChallenge: PredefinedChallenge,
): EditableCurrentChallenge {
    return {
        description: predefinedChallenge.title,
        actions: [],
        state: CurrentChallengeState.New,
        is_active: true,
    };
}

export function isSameChallenge(
    pdc: PredefinedChallenge,
    editableCurrentChallenge: EditableCurrentChallenge,
) {
    return (
        !!editableCurrentChallenge.description &&
        !!pdc.title &&
        editableCurrentChallenge.description === pdc.title
    );
}

export const useCurrentChallengeStore = defineStore({
    id: 'current-challenges-store',
    state: (): CurrentChallengeStoreState => ({
        predefined: [],
        originalValues: [],
        selected: [],
        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        predefinedSelected(state): PredefinedChallenge[] {
            return state.predefined.filter((pdc) => {
                return state.selected.some((challenge) => isSameChallenge(pdc, challenge));
            });
        },
        customChallenges(state): EditableCurrentChallenge[] {
            return state.selected.filter((challenge) => {
                return !state.predefined.some((pdc) => isSameChallenge(pdc, challenge));
            });
        },
        activeChallenges(state): EditableCurrentChallenge[] {
            return state.selected.filter((challenge) => {
                return challenge.is_active;
            });
        },
        isDirty(state): boolean {
            // TODO: This is not totally correct, because we are not comparing the actions
            return !isEqual(state.selected, state.originalValues);
        },
        isAreaStarted(): boolean {
            return this.activeChallenges.length > 0;
        },
        isAreaComplete(): boolean {
            return this.activeChallenges.length >= 2;
        },
        needsLoading(state): boolean {
            // This might not be totally correct when the data has been already loaded
            // but the values are empty. The downside is that the request will be fired.
            // Not a big problem.
            // TODO: Make originalValues null instead of empty or add a loaded flag
            return state.originalValues.length === 0;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Current challenges already loaded');
                return;
            }

            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            console.info('Loading current challenges...');

            setInLoading(this);

            try {
                const canvasCurrentChallengesApiObjs = await fetchCanvasCurrentChallenges(
                    canvasId,
                    accessToken,
                );

                this.predefined = await fetchPredefinedChallenges(accessToken);
                const canvasCurrentChallenges =
                    canvasCurrentChallengesApiObjs.map(makeEditableChallenge);
                this._setChallenges(canvasCurrentChallenges);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        togglePredefined(predefined: PredefinedChallenge) {
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id

            const found = this.selected.find((challenge) => isSameChallenge(predefined, challenge));
            if (found) {
                const index = this.selected.indexOf(found);
                this.selected.splice(index, 1);
            } else {
                this.selected.push(makeEditableChallengeFromPredefined(predefined));
            }
        },
        onAddChallenge(text: string): void {
            this.selected.push({
                description: text,
                actions: [],
                state: CurrentChallengeState.New,
                is_active: true,
            });
        },
        onRemove(challenge: EditableCurrentChallenge): void {
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id
            // TODO: handle newly created pdc without id

            const index = this.selected.indexOf(challenge);

            if (index >= 0) {
                this.selected.splice(index, 1);
            } else {
                console.error('Challenge not found');
                return;
            }
        },
        async saveProgress(): Promise<void> {
            setInUpdating(this);

            try {
                const updateData = this.selected.map(
                    (challenge: EditableCurrentChallenge): CanvasCurrentChallengeItem => {
                        return {
                            id: challenge.id,
                            description: challenge.description,
                            actions: challenge.actions,
                            state: challenge.state,
                            // Temporary HACK to Force link between each challenge and the current plan id
                            // We could add a UI artifact to link the challenge to the plan or not, but at this point
                            // it is easier to make every challenge linked to the plan
                            challenge_path_id: useCanvasStore().canvas?.challenge_path_id,
                            is_active: challenge.is_active,
                        };
                    },
                );

                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                await updateCanvasCurrentChallenge(canvasId, updateData, accessToken);

                const canvasCurrentChallengesApiObjs = await fetchCanvasCurrentChallenges(
                    canvasId,
                    accessToken,
                );
                const canvasCurrentChallenges =
                    canvasCurrentChallengesApiObjs.map(makeEditableChallenge);

                this._setChallenges(canvasCurrentChallenges);

                setInIdle(this);
            } catch (error: unknown) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, error);
            }
        },

        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setChallenges: function (rawValues: EditableCurrentChallenge[]) {
            this.originalValues = rawValues;
            this.selected = cloneDeep(rawValues);
        },
    },
});
