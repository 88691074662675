<template>
    <StorySectionActivityList :items="items" class="fill-height" />
</template>

<script setup lang="ts">
    import { useCurrentChallengeStore } from '@/store/current-challenges/store';
    import StorySectionActivityList from '@/components/story/section/activity/StorySectionActivityList.vue';
    import { computed } from 'vue';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        item: StoryActivity;
    }>();

    const store = useCurrentChallengeStore();

    const items = computed(() => {
        return store.activeChallenges.map((item) => item.description);
    });
</script>
