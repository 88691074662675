<template>
    <div class="text-heading-h3">First, introductions</div>

    <div class="mt-10">
        <v-text-field
            label="First name"
            variant="outlined"
            v-model="userInputs.first_name"
            :rules="[requiredRule('First name')]"
            required
            validate-on="input"
        />

        <v-text-field
            label="Last name"
            variant="outlined"
            v-model="userInputs.last_name"
            :rules="[requiredRule('Last name')]"
            validate-on="input"
        />

        <v-text-field
            prepend-inner-icon="mdi-email"
            label="Email"
            variant="outlined"
            readonly
            v-model="userInputs.email"
        />

        <v-text-field
            label="Phone Number"
            variant="outlined"
            v-model="userInputs.phone_number"
            :rules="[phoneNumberRules]"
            :hide-details="false"
            placeholder="+64212345678"
            validate-on="input"
        />

        <v-autocomplete
            label="Gender"
            variant="outlined"
            class="mb-2"
            v-model="userInputs.gender"
            :hide-details="false"
            :return-object="false"
            :items="genderOptions"
            :rules="[requiredRule('Gender')]"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-text-field
            label="Date of Birth"
            variant="outlined"
            :rules="[requiredRule('Date of Birth')]"
            type="date"
            :model-value="dateValue"
            @update:model-value="onChangeDate"
            validate-on="input"
        />

        <v-autocomplete
            label="Nationality"
            variant="outlined"
            :chips="true"
            :hide-details="false"
            :clearable="true"
            :auto-select-first="true"
            v-model="userInputs.nationality"
            :items="nationalityOptions"
            :return-object="false"
            :rules="[requiredRule('Nationality')]"
            class="mb-2"
            item-title="title"
            item-value="value"
            validate-on="input"
        />
    </div>

    <div class="w-100 mt-4">
        <p>
            Please also take a moment to read and agree to our Terms and Conditions and Privacy
            Policy
        </p>
    </div>

    <v-row no-gutters class="w-100 px-2 text-paragraph-md">
        <v-checkbox
            style="height: 40px"
            density="compact"
            v-model="localTermsAndConditionModel"
            class="opacity-100"
        >
            <template v-slot:label>
                Accept &nbsp;
                <router-link :to="termsRoute" target="_blank" class="text-custom-neutral-black"
                    >Terms and Conditions
                </router-link>
            </template>
        </v-checkbox>
    </v-row>
    <v-row no-gutters class="w-100 px-2 text-paragraph-md">
        <v-checkbox style="height: 40px" density="compact" v-model="localPrivacyModel">
            <template v-slot:label>
                Accept &nbsp;
                <router-link :to="privacyRoute" target="_blank" class="text-custom-neutral-black"
                    >Privacy Policy
                </router-link>
            </template>
        </v-checkbox>
    </v-row>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { privacyRoute, termsRoute } from '@/router/routes';
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import { phoneNumberRules, requiredRule } from '@/components/profile/rules';
    import { genderOptions, nationalityOptions } from '@/components/profile/userInputOptions';

    const props = defineProps({
        termsAndConditionModel: {
            type: Boolean,
            required: true,
        },
        privacyModel: {
            type: Boolean,
            required: true,
        },
    });
    const emit = defineEmits(['update:termsAndConditionModel', 'update:privacyModel', 'next']);

    // Computed properties for two-way binding
    const localTermsAndConditionModel = computed({
        get: () => props.termsAndConditionModel,
        set: (value) => emit('update:termsAndConditionModel', value),
    });

    const localPrivacyModel = computed({
        get: () => props.privacyModel,
        set: (value) => emit('update:privacyModel', value),
    });

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);

    const dateValue = computed(() => {
        return userInputs?.value.date_of_birth ?? null;
    });

    const onChangeDate = (value: string) => {
        userInputs.value.date_of_birth = value;
    };
</script>
