<template>
    <v-img :src="logoSrc" :height="props.height ?? 40" :width="props.width ?? 40" />
</template>

<script setup lang="ts">
    import logoSrc from '@/assets/images/logo/actvo-logo.svg';

    const props = defineProps<{
        height?: string | number | undefined;
        width?: string | number | undefined;
    }>();
</script>
