<template>
    <PersonalValuesSecondStep />
</template>

<script setup lang="ts">
    import { onBeforeUnmount } from 'vue';
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { useCanvasStore } from '@/store/canvas/store';
    import PersonalValuesSecondStep from '@/components/canvas/personal-values/upload/second-step/PersonalValuesSecondStep.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { useActivityStore } from '@/store/activity/store';

    const canvasStore = useCanvasStore();
    const store = usePersonalValuesStore();
    const activityStore = useActivityStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PersonalValues);

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalValues);
    });
</script>
