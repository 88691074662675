import { PersonalValueCategory } from '@/api/types/personalValue';

export const getCategoryIcon = (category: PersonalValueCategory) => {
    switch (category) {
        case PersonalValueCategory.Independence:
            return 'mdi-flag';
        case PersonalValueCategory.Achievement:
            return 'mdi-map';
        case PersonalValueCategory.Empathy:
            return 'mdi-hand-heart';
        case PersonalValueCategory.Adventure:
            return 'mdi-elevation-rise';
        case PersonalValueCategory.Creativity:
            return 'mdi-palette';
        case PersonalValueCategory.Harmony:
            return 'mdi-handshake'
        default:
            break;
    }
};

export function standardCategory(value: string) {
    switch (value) {
        case PersonalValueCategory.Independence:
            return 'independence';
        case PersonalValueCategory.Achievement:
            return 'achievement';
        case PersonalValueCategory.Empathy:
            return 'empathy';
        case PersonalValueCategory.Adventure:
            return 'adventure';
        case PersonalValueCategory.Creativity:
            return 'creativity';
        case PersonalValueCategory.Harmony:
            return 'harmony';
        default:
            return 'Unknown';
    }
}