<template>
    <div class="text-heading-h3">Final couple of questions</div>
    <div class="w-100 mt-8">
        <v-autocomplete
            label="NZ Region"
            variant="outlined"
            :items="nzRegionOptions"
            :return-object="false"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            v-model="userInputs.location"
            :rules="[requiredRule('NZ Region')]"
            class="mb-2"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            class="mb-2"
            label="Visa Status"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :items="visaOptions"
            v-model="userInputs.visa_type"
            :rules="[requiredRule('Visa')]"
            :return-object="false"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            class="mb-2"
            label="Primary language"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :items="primaryLanguages"
            v-model="userInputs.primary_language"
            :rules="[requiredRule('Primary language')]"
            :return-object="false"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            class="mb-2"
            label="Other languages"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :multiple="true"
            :items="otherLanguages"
            v-model="userInputs.other_languages"
            :return-object="false"
            item-title="title"
            item-value="value"
        />
    </div>
</template>

<script setup lang="ts">
    import { computed, watch } from 'vue';
    import { requiredRule } from '@/components/profile/rules';
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import {
        familiarLanguageOptions,
        nzRegionOptions,
        otherLanguageOptions,
        visaOptions,
    } from '@/components/profile/userInputOptions';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);

    const primaryLanguages = computed(() => [...familiarLanguageOptions, ...otherLanguageOptions]);

    watch(
        () => userInputs.value.primary_language,
        (newValue) => {
            if (newValue && userInputs.value.other_languages?.includes(newValue)) {
                userInputs.value.other_languages = userInputs.value.other_languages.filter(
                    (language: string) => language !== newValue,
                );
            }
        },
    );

    const otherLanguages = computed(() => {
        const options = [...familiarLanguageOptions, ...otherLanguageOptions];

        if (userInputs.value.primary_language) {
            return options.filter((option) => option.value !== userInputs.value.primary_language);
        }

        return options;
    });
</script>

<style scoped lang="scss">
    :deep(.v-expansion-panel-text__wrapper) {
        padding-left: 16px;
        padding-right: 16px;
    }
</style>
