<template>
    <v-container id="#story-print-container" class="pa-0 align-baseline" fluid>
        <teleport to="#before-main-container">
            <!-- This content is teleported outside of the main container to avoid the global padding -->
            <StoryHeading v-if="activityStore.lastCompleted" />
        </teleport>

        <v-row class="pa-0 w-100" no-gutters :class="isDesktop ? 'mb-4' : ''">
            <ContextualInlineGuide :section="InlineGuideSection.Story" />
        </v-row>

        <v-row
            v-if="showUniqueValueProposition"
            class="pa-0 w-100"
            no-gutters
            :class="isDesktop ? 'mb-8' : 'mb-4'"
        >
            <StoryUniqueValueProposition />
        </v-row>

        <v-row class="pa-0 w-100" no-gutters :class="isDesktop ? 'mb-8' : 'mb-4'">
            <!-- The what I offer section is always expanded -->
            <StorySection :section="whatIOffer" />
        </v-row>

        <v-row class="pa-0 w-100" no-gutters :class="isDesktop ? 'mb-8' : 'mb-4'">
            <StorySectionNotStarted
                v-if="
                    whoIAm.status === StorySectionState.NotStarted &&
                    !uiStoryStore.whoIAmSectionExpanded
                "
                @click="uiStoryStore.expandWhoIAm"
                :section="whoIAm"
            />
            <StorySection v-else :section="whoIAm" />
        </v-row>
        <v-row class="pa-0 w-100" no-gutters>
            <StorySectionNotStarted
                v-if="
                    whatIAmWorkingOn.status === StorySectionState.NotStarted &&
                    !uiStoryStore.whatIAmWorkingOnSectionExpanded
                "
                @click="uiStoryStore.expandWhatIAmWorkingOn"
                :section="whatIAmWorkingOn"
            />
            <StorySection v-else :section="whatIAmWorkingOn" />
        </v-row>

        <v-row
            class="pa-0 w-100"
            v-if="showChallengePathCard"
            no-gutters
            :style="{ 'margin-top': isDesktop ? '80px' : '64px' }"
        >
            <StoryChallengePathCard />
        </v-row>

        <StoryGuide />
    </v-container>
</template>

<script setup lang="ts">
    import StorySection from '@/components/story/section/StorySection.vue';
    import { useStoryStore } from '@/store/story/store';
    import StoryUniqueValueProposition from '@/components/story/section/activity/content/uvp/StoryUniqueValueProposition.vue';
    import StoryHeading from '@/components/story/StoryHeading.vue';
    import { useActivityStore } from '@/store/activity/store';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';
    import skillsStackSrc from '@/assets/images/story/my-personal-skills.svg';
    import { computed } from 'vue';
    import personalValuesSrc from '@/assets/images/story/personal-values.svg';
    import myCurrentChallengesSrc from '@/assets/images/story/my-current-challenges.svg';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { sectionStatus, showFeedbackQuestion } from '@/components/story/section/util';
    import StorySectionNotStarted from '@/components/story/section/StorySectionNotStarted.vue';
    import { StorySectionId, type StorySectionItem, StorySectionState } from '@/store/story/types';
    import StoryChallengePathCard from '@/components/story/StoryChallengePathCard.vue';
    import StoryGuide from '@/components/story/StoryGuide.vue';
    import { InlineGuideSection } from '@/store/inline-guide/types';
    import { useUIStoryStore } from '@/store/story/uiStoryStore';

    const store = useStoryStore();
    const activityStore = useActivityStore();

    const { isDesktop } = useIsDesktop();

    const uiStoryStore = useUIStoryStore();

    const whoIAm = computed((): StorySectionItem => {
        const section = store.story?.whoIAm;
        return {
            id: StorySectionId.WhoIAm,
            title: 'Who I Am',
            description:
                'I bring my full self to work. ' +
                'My personal strengths are unique to me, and they are shaped ' +
                'by my personality type and the values that I hold.',

            statement: section ?? null,
            image: personalValuesSrc,
            status: sectionStatus(store.whoIAmActivities),
            activities: store.whoIAmActivities,
            showFeedbackQuestion: section ? showFeedbackQuestion(section) : false,
        };
    });

    const whatIOffer = computed((): StorySectionItem => {
        const section = store.story?.whatIOffer;
        const statement = section?.content ?? null;
        return {
            id: StorySectionId.WhatIOffer,
            title: 'What I Offer',
            description:
                'My contribution to the workforce is unique. ' +
                "It's shaped by the experiences I've had and the skills I offer. " +
                'This is what makes me valuable.',
            statement: section ?? null,
            image: skillsStackSrc,
            activities: store.whatIOffserActivities,
            status: sectionStatus(store.whatIOffserActivities),
            showFeedbackQuestion: section ? showFeedbackQuestion(section) : false,
        };
    });

    const whatIAmWorkingOn = computed((): StorySectionItem => {
        const section = store.story?.whatIAmWorkingOn;
        return {
            id: StorySectionId.WhatIAmWorkingOn,
            title: "What I'm working on",
            description:
                "I'm addressing challenges that will help me grow, " +
                'and shaping my future career path. ' +
                'This is what I want support with.',

            statement: section ?? null,
            image: myCurrentChallengesSrc,
            activities: store.whatIAmWorkingOnActivities,
            status: sectionStatus(store.whatIAmWorkingOnActivities),
            showFeedbackQuestion: section ? showFeedbackQuestion(section) : false,
        };
    });

    const showChallengePathCard = computed(() => {
        return (
            whatIOffer.value.status === StorySectionState.Completed &&
            whoIAm.value.status === StorySectionState.Completed &&
            whatIAmWorkingOn.value.status === StorySectionState.Completed
        );
    });

    const showUniqueValueProposition = computed(() => {
        return store.story?.uvp;
    });
</script>
