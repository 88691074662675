<template>
    <div
        class="mt-4 font-weight-regular"
        :class="isDesktop ? 'text-paragraph-sm' : 'text-paragraph-xs'"
    >
        Doesn't sound like you?
        <a
            @click="emit('click', false)"
            class="font-weight-600 text-decoration-underline cursor-pointer"
        >Regenerate</a>
    </div>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['click']);
</script>
