export type ActivityLink = {
    title: string;
    url: string;
};

/**
 * Parse the links from a string to an array of strings.
 */
export function parseLinks(links?: string | null): ActivityLink[] {
    if (!links) {
        return [];
    }

    try {
        return JSON.parse(links);
    } catch (e) {
        return [];
    }
}
