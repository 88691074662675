<template>
    <app-guide-inline
        v-if="store.selectableActions.length"
        :closable="true"
        text="I’ve put together a plan for you to put these activities into action. Feel free to adjust this to suit your own learning style!"
        @close="showDummyGuide = false"
        @submit="showDummyGuide = false"
    />
    <app-guide-chat-activator v-if="showDummyGuide" />
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';

    const store = useSingleChallengePathStore();

    const showDummyGuide = ref(true);
</script>
