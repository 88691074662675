<template>
    <v-expansion-panels variant="accordion" v-model="openPanels" multiple>
        <v-expansion-panel elevation="0" class="" v-for="(item, index) in items">
            <v-expansion-panel-title class="pl-0 text-paragraph-md font-weight-600">
                <slot v-if="hasSlot(`title-${index}`)" :name="`title-${index}`" />
                <span v-else>{{ item.title }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="pl-0">
                <div class="text-paragraph-sm">{{ item.text }}</div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup lang="ts">
    import { defineProps, ref, useSlots } from 'vue';

    const props = defineProps<{
        items: { title?: string; text?: string }[];
    }>();

    // Open all panels by default
    const openPanels = ref(props.items.map((_, index) => index));

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    :deep(.v-expansion-panel-title__overlay) {
        background-color: unset;
    }

    :deep(.v-expansion-panel-text__wrapper) {
        padding-left: 0;
        padding-top: 0px;
    }
</style>
