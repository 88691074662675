<template>
    <v-dialog
        :model-value="modelValue"
        :persistent="true"
        :fullscreen="true"
        :scrim="false"
        :content-class="`tutorial-dialog ${responsiveClass}`"
    >
        <v-card
            class="fill-height pa-4 py-16 app-guide-modal-card text-white"
            align="center"
            :class="responsiveClass"
        >
            <v-container class="app-guide-modal-container pa-0" :class="`variant-${variant}`">
                <div no-gutters class="app-guide-modal-content">
                    <app-guide-avatar size="96" :animate="animateAlex" class="" />

                    <div class="pt-12 mb-6 text-uppercase text-center text-caption-subtitle w-100">
                        {{ sectionName }}
                    </div>

                    <div class="mb-6 text-heading-h1 font-weight-bold w-100">
                        <slot name="title" :step="step" />
                    </div>

                    <div class="app-guide-modal-content-paragraph w-100">
                        <slot :step="step" />
                    </div>
                </div>

                <div class="app-guide-modal-navigation">
                    <app-button-icon
                        @click="closeAndMaybeNavigateBack"
                        v-show="step === 1"
                        size="x-small"
                        color="white"
                        icon="mdi-arrow-left"
                        variant="outlined"
                        class="navigation-previous"
                    />
                    <app-button-icon
                        @click="previous"
                        v-show="step > 1"
                        size="x-small"
                        color="white"
                        icon="mdi-arrow-left"
                        variant="outlined"
                        class="navigation-previous"
                    />
                    <div class="py-6 px-0">
                        <div
                            v-if="steps > 1"
                            class="navigation-circle mx-2"
                            v-for="s in range(0, steps)"
                            :key="s"
                            :class="{
                                'navigation-circle-done': s + 1 <= step,
                            }"
                            icon
                            @click="step = s + 1"
                        />

                        <div
                            class="w-100 mt-2 text-white text-paragraph-sm font-weight-700"
                            style="position: absolute; left: 0"
                            v-if="hasSkip && !isLast"
                        >
                            <span @click="close" class="cursor-pointer">Skip</span>
                        </div>
                    </div>

                    <slot
                        v-if="hasSlot('actions')"
                        name="actions"
                        :step="step"
                        :next="next"
                        :previous="previous"
                        :is-last="isLast"
                        :close="close"
                    />
                    <app-button
                        v-else-if="isLast"
                        @click="close"
                        color="primary"
                        variant="flat"
                        append-icon="mdi-arrow-right"
                        weight="bold"
                        :class="steps > 1 ? 'navigation-next' : ''"
                        :disabled="disabledNext"
                        >{{ actionText }}
                    </app-button>
                    <app-button-icon
                        v-else
                        @click="next"
                        size="x-small"
                        color="primary"
                        icon="mdi-arrow-right"
                        :class="steps > 1 ? 'navigation-next' : ''"
                        :disabled="disabledNext"
                        variant="flat"
                    />
                </div>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, ref, useSlots, watch } from 'vue';
    import { range } from 'lodash';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const step = ref(1);

    watch(
        () => step.value,
        (value) => {
            emit('change-step', value);
        },
    );

    const { responsiveClass } = useIsDesktop();

    const props = withDefaults(
        defineProps<{
            variant?: 'short' | 'long';
            sectionName?: string;
            actionText: string;
            modelValue: boolean;
            steps: number;
            animateAlex?: boolean;
            disabledNext?: boolean;
            navigateBack?: boolean;
        }>(),
        {
            actionText: 'Close',
            variant: 'short',
            steps: 1,
            disabledNext: false,
            navigateBack: false,
        },
    );

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['update:modelValue', 'change-step']);

    const close = () => {
        emit('update:modelValue', false);
    };

    const hasSkip = computed(() => {
        return props.steps > 1;
    });

    const previous = () => {
        step.value -= 1;
    };

    const next = () => {
        step.value += 1;
    };

    const watcher = watch(
        () => props.modelValue,
        (value) => {
            if (value) {
                step.value = 1;
            }
        },
    );

    onBeforeUnmount(() => {
        watcher();
    });

    const isLast = computed(() => {
        return step.value === props.steps;
    });

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };

    const closeAndMaybeNavigateBack = () => {
        close();

        if (props.navigateBack) {
            router.back();
        }
    };
</script>

<style scoped lang="scss">
    .app-guide-modal-card {
        background: linear-gradient(
            93.38deg,
            rgb(var(--v-theme-secondary-blue)) 0%,
            rgb(var(--v-theme-tertiary-purple)) 100%
        );

        &.desktop {
            .app-guide-modal-card-title {
                max-width: 640px;
            }

            .app-guide-modal-card-content {
                max-width: 640px;
            }

            .app-guide-modal-navigation {
                max-width: 640px;
            }

            .app-guide-modal-content-paragraph {
                font-size: 18px;
            }
        }

        &.mobile {
            .app-guide-modal-card-title {
                width: 100%;
            }

            .app-guide-modal-card-content {
                width: 100%;
            }

            .app-guide-modal-content-paragraph {
                font-size: 16px;
            }
        }
    }

    .app-guide-modal-container {
        display: flex;
        max-width: 640px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
    }

    .app-guide-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        flex: 1 0 0;
        align-self: stretch;
        padding: 0;

        :deep(p) {
            margin-bottom: 16px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .app-guide-modal-navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        position: relative;

        .navigation-previous {
            position: absolute;
            left: 0;
        }

        .navigation-next {
            position: absolute;
            right: 0;
        }

        .navigation-circle {
            cursor: pointer;
            width: 15px; /* The width of the circle */
            height: 15px; /* The height of the circle */
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 50%; /* Makes it a circle */
            display: inline-block; /* Ensures the circle behaves like a block */

            &.navigation-circle-done {
                background-color: white;
            }
        }
    }
</style>

<!-- Note: Do not use scoped styles, as the dialog lives in a v-overlay outside this tree-->
<style lang="scss">
    // this values 116px and 124px are the height of the header.
    // I should be able to use the --padding-top-main varialbe defined in App.vue, but for some
    // reason it does not work
    // TODO: review duplication

    .tutorial-dialog.desktop {
        margin-top: 116px !important;
        height: calc(100% - 116px) !important;
    }

    .tutorial-dialog.mobile {
        margin-top: 124px !important;
        height: calc(100% - 124px) !important;
    }
</style>
