import { asyncTimeout } from '@/lib';
import type { AxiosRequestConfig } from 'axios';
import { throwIfAborted } from '@/lib/genericAbortSignal';
import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import { fetchPathwayChallenge } from '@/services/plan/current/challenge/service';

// const log = taggedLogger('poll-challenge');

/** 5 minute timeout */
const DEFAULT_POLL_TIMEOUT_MILLISECONDS = 60 * 1000 * 5;

/** Poll every second */
const POLL_INTERVAL_MILLISECONDS = 10000 as const;

/** Log every 5th attempt at info level */
const LOG_ATTEMPT_AT_INFO = 5 as const;

export class GenerateSuggestionsTimeoutError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, GenerateSuggestionsTimeoutError.prototype);
    }
}

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 *
 * @throws GenerateSuggestionsTimeoutError if the timeout elapses.
 */
export async function pollChallenge(
    canvasId: number,
    challengeId: number,
    condition: (challenge: CanvasCurrentChallenge) => boolean,
    config: AxiosRequestConfig & { pollTimeout?: number },
    accessToken: string,
): Promise<CanvasCurrentChallenge> {
    // Note: we were previously checking whether the case had a valid template link in this function
    // We may have to do that again

    // Calculate the time we need to time-out
    const timeout = config?.pollTimeout ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS;
    const timeoutTime = Date.now() + timeout;

    // Attempt to fetch the template
    const fetch = async (): Promise<CanvasCurrentChallenge | null> => {
        const challenge = await fetchPathwayChallenge(canvasId, challengeId, accessToken);
        return condition(challenge) ? challenge : null;
    };

    for (let attempt = 1; ; ++attempt) {
        throwIfAborted(config?.signal);

        // Log every Nth attempt at info level
        const logLevel = attempt % LOG_ATTEMPT_AT_INFO === 0 ? 'info' : 'debug';
        console.log(logLevel, 'Attempt %s to fetch challenge...', attempt);

        // Attempt to fetch the user challenge
        const challenge = await fetch();
        if (challenge) {
            if (attempt > 1) {
                console.info('Challenge fetched after %d attempts', attempt);
            }
            return challenge;
        }
        throwIfAborted(config?.signal);

        if (Date.now() >= timeoutTime) {
            throw new GenerateSuggestionsTimeoutError('Timed-out while attempting to fetch challenge');
        }

        await asyncTimeout(POLL_INTERVAL_MILLISECONDS, config?.signal);
    }
}
