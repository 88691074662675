import { type ApiResponse, get, post, put } from '@/services/apiClient';
import type {
    ChallengeActionCreateData,
    ChallengeActionUpdateData,
    CurrentChallengeStateUpdateData,
    SuggestedChallengeAction,
} from '@/api/types/plan/currentChallengeAction';
import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';

function challengePath(canvasId: number, challengeId: number): string {
    return `canvas/${canvasId}/pathway/challenge/${challengeId}`;
}

function challengeStatePath(canvasId: number, challengeId: number): string {
    return `${challengePath(canvasId, challengeId)}/state`;
}

export const getPathwayChallenge = function (
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<ApiResponse<CanvasCurrentChallenge>> {
    const request = get<CanvasCurrentChallenge>(challengePath(canvasId, challengeId));
    return request(accessToken);
};

export const putPathwayChallengeState = function (
    canvasId: number,
    challengeId: number,
    updateData: CurrentChallengeStateUpdateData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<CurrentChallengeStateUpdateData, any>(
        challengeStatePath(canvasId, challengeId),
    );
    return request(accessToken, updateData);
};

export const putPathwayChallengeDeactivate = function (
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<any, any>(`${challengePath(canvasId, challengeId)}/deactivate`);
    return request(accessToken);
};

export const putPathwayChallengeActivate = function (
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<any, any>(`${challengePath(canvasId, challengeId)}/activate`);
    return request(accessToken);
};

export type StartChallengeCreateActionData = {
    actions: (ChallengeActionCreateData | SuggestedChallengeAction)[];
};

export type UpdateChallengeActionData = {
    actions: (ChallengeActionUpdateData | ChallengeActionCreateData | SuggestedChallengeAction)[];
};

export const postPathwayChallengeStart = function (
    canvasId: number,
    challengeId: number,
    createData: StartChallengeCreateActionData,
    accessToken: string,
): Promise<ApiResponse<CanvasCurrentChallenge>> {
    const request = post<StartChallengeCreateActionData, CanvasCurrentChallenge>(
        `${challengePath(canvasId, challengeId)}/start`,
    );
    return request(accessToken, createData);
};

export const putPathwayChallengeUpdate = function (
    canvasId: number,
    challengeId: number,
    createData: UpdateChallengeActionData,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<UpdateChallengeActionData, void>(
        `${challengePath(canvasId, challengeId)}/update`,
    );
    return request(accessToken, createData);
};
