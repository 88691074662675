<template>
    <v-card
        class="bg-custom-grey-200 border-2-grey-200 px-0 pt-3 rounded-8"
        elevation="0"
        variant="outlined"
        :class="responsiveClass"
    >
        <v-card-item class="pa-0 px-5 pb-3 w-100">
            <span class="text-paragraph-lg font-weight-600">{{ item.description }}</span>
        </v-card-item>
        <v-card-text class="bg-white mt-0 pa-0 text-paragraph-sm d-flex align-center">
            <app-button
                height="38"
                @click="onAddToSkills"
                :disabled="isWorking"
                :loading="isAdding"
                variant="plain"
                class="w-50 py-2 pr-4 align-center justify-end d-flex text-custom-neutral-black text-paragraph-sm font-weight-600 cursor-pointer"
            >
                <v-icon class="mr-3">mdi-check</v-icon>
                <span>Add to my personal strength</span>
            </app-button>
            <v-divider
                vertical
                length="38"
                thickness="1"
                class="mx-0"
                color="custom-neutral-grey-200"
                opacity="100"
            />
            <app-button
                height="38"
                @click="onRemoveSkill"
                :disabled="isWorking"
                :loading="isRemoving"
                variant="plain"
                class="w-50 py-2 px-0 pl-4 justify-start d-flex align-center text-custom-neutral-grey-600 text-paragraph-sm font-weight-600 cursor-pointer"
            >
                <v-icon class="mr-3">mdi-close</v-icon>
                <span>Remove</span>
            </app-button>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import type { InferredCanvasSkill } from '@/api/types/canvas/skills';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import { computed, ref } from 'vue';

    const props = defineProps<{
        item: InferredCanvasSkill;
    }>();

    const store = usePersonalStrengthsStore();

    const { responsiveClass } = useIsDesktop();

    const emit = defineEmits(['edit-click', 'delete-click', 'change-type']);

    const isAdding = ref(false);
    const isRemoving = ref(false);

    const isWorking = computed(() => isAdding.value || isRemoving.value);

    const onAddToSkills = async () => {
        try {
            isAdding.value = true;
            await store.acceptInferredSkill(props.item);
        } finally {
            isAdding.value = false;
        }
    };

    const onRemoveSkill = async () => {
        try {
            isRemoving.value = true;
            await store.denyInferredSkill(props.item)
        } finally {
            isRemoving.value = false;
        }
    };

</script>

<style scoped lang="scss"></style>
