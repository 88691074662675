<template>
    <ContextualInlineGuide class="mt-10" :section="InlineGuideSection.PersonalStrength" />

    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.PersonalStrengths" />
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import { InlineGuideSection } from '@/store/inline-guide/types';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';

    const store = usePersonalStrengthsStore();
</script>
