import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import { CurrentChallengeState } from '@/api/types/canvas/currentChallenge';
import {
    ActionType,
    type ChallengeActionCreateData,
    type ChallengeActionNewForm,
    type CurrentChallengeAction,
    CurrentChallengeActionState,
    type SuggestedChallengeAction,
} from '@/api/types/plan/currentChallengeAction';
import { PlanActionItemType } from '@/store/plan/common/types';
import { makeDueDate } from '@/store/plan/common/utils';
import { toBrowserFormat } from '@/lib/dates';

// Guard clause function to ensure action is of type 'Action'
export function isCurrentChallengeAction(action: any): action is CurrentChallengeAction {
    return action.type === ActionType.UserCreated && action.id !== undefined;
}

export function isChallengeActionCreateData(action: any): action is ChallengeActionCreateData {
    return action.id === undefined;
}

// Guard clause function to ensure action is of type 'Suggested'
export function isSuggestedAction(action: any): action is SuggestedChallengeAction {
    return action.type === ActionType.Suggested;
}

export function isNew(challenge: CanvasCurrentChallenge): challenge is CanvasCurrentChallenge & {
    state: CurrentChallengeState.New;
} {
    return challenge.state === CurrentChallengeState.New && challenge.actions.length === 0;
}

export function isInProgress(
    challenge: CanvasCurrentChallenge,
): challenge is CanvasCurrentChallenge & {
    state: CurrentChallengeState.InProgress;
} {
    return challenge.state === CurrentChallengeState.InProgress && challenge.actions.length > 0;
}

export function isCompleted(
    challenge: CanvasCurrentChallenge,
): challenge is CanvasCurrentChallenge & {
    state: CurrentChallengeState.Completed;
} {
    return (
        challenge.state === CurrentChallengeState.Completed &&
        challenge.actions.length > 0 &&
        challenge.actions.every((action) => action.state === CurrentChallengeActionState.Completed)
    );
}

// Guard function to validate the challenge state
export function validateChallenge(challenge: CanvasCurrentChallenge): void {
    if (isNew(challenge)) {
        // Challenge is in New state with no actions
        console.log('Challenge is valid: New state with no actions.');
    } else if (isInProgress(challenge)) {
        // Challenge is in InProgress state with actions present
        console.log('Challenge is valid: InProgress state with actions present.');
    } else if (isCompleted(challenge)) {
        // Challenge is in Completed state with no actions
        console.log('Challenge is valid: Completed state with no actions.');
    } else {
        throw new Error(
            `Invalid challenge state. ${challenge.state}. Actions: ${challenge.actions.length}. Suggestions: ${challenge.suggested_actions.length}`,
        );
    }
}

export function makeNewActivity(): ChallengeActionNewForm {
    return {
        title: '',
        description: '',
        type: ActionType.UserCreated,
        // TODO: make this configurable
        action_type: PlanActionItemType.ExperienceActionType,
        long_description: '',
        links: null,
        due_date: toBrowserFormat(makeDueDate()),
        state: CurrentChallengeActionState.New,

        frequency: 'daily',
        duration_min: 15,
        duration_max: 30,
        difficulty: 'easy', // TODO: user to change this
    };
}
