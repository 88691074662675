<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Personal Values"
        action-text="Let's go"
        :steps="3"
        :navigate-back="navigateBack"
    >
        <template #title="{ step }">
            <span v-if="step === 1">Welcome to the values activity!</span>
            <span v-else-if="step === 2">Choose your values</span>
            <span v-else-if="step === 3">Select, review and refine</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Values are something we all hold as humans, and help us make decisions and guide behaviours. This is a tricky activity but we’ll guide you through! It's a process of what matters most to you now, in your working life.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    Take your time and choose carefully, as you can only hold 6 Core Values and 6 Growth Values.
                </p>
                <p>
                    Your Core Values are the values that guide your life decisions, and generally don't change. Your Growth Values are those you'd like to grow into and adopt more in your future.
                </p>
            </div>
            <div v-else-if="step === 3">
                <p>
                    Click the left or right button to scroll through the value cards, and add them to your stacks. Feel free to rearrange after you’ve added them by dragging and dropping. If you decide a value is no longer important, just remove it.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
        navigateBack?: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
