export enum InlineGuideSection {
    Story = 'story',
    Skills = 'skills',
    PersonalStrength = 'personal_strengths',
}

export enum InlineGuideActionName {
    GotoCareerHistory = '{{goto_career_history}}',
    GotoValues = '{{goto_values}}',
    GotoPersonalityType = '{{goto_personality_type}}',
    GotoMemorableExperiences = '{{goto_learned_experiences}}',
    GotoSkills = '{{goto_skills}}',
    GotoPersonalStrength = '{{goto_personal_strengths}}',
    GotoCurrentChallenges = '{{goto_current_challenges}}',
    GotoFutureAspiration = '{{goto_future_aspiration}}',

    UploadCv = '{{upload_cv}}',
    ReTryUploadCv = '{{re_try_upload_cv}}',

    ActivitiesComplete = '{{activities_complete}}',

    PersonalStrengthsFoundInferred = '{{personal_strengths__found_inferred_strengths}}',
    SkillsFoundInferred = '{{skills__found_inferred_skills}}',
}

export const navigationActions = [
    InlineGuideActionName.GotoCareerHistory,
    InlineGuideActionName.GotoValues,
    InlineGuideActionName.GotoPersonalityType,
    InlineGuideActionName.GotoMemorableExperiences,
    InlineGuideActionName.GotoSkills,
    InlineGuideActionName.GotoPersonalStrength,
    InlineGuideActionName.GotoCurrentChallenges,
    InlineGuideActionName.GotoFutureAspiration,
] as const;
export type NavigationActionKey = (typeof navigationActions)[number];

export const withCallbackActions = [
    InlineGuideActionName.UploadCv,
    InlineGuideActionName.ReTryUploadCv,
] as const;
export type WithCallbackActionKey = (typeof withCallbackActions)[number];

export const withImmediateCallbackActions = [
    InlineGuideActionName.PersonalStrengthsFoundInferred,
    InlineGuideActionName.SkillsFoundInferred,
] as const;
export type WithImmediateCallbackActionKey = (typeof withImmediateCallbackActions)[number];

export const otherActions = [InlineGuideActionName.ActivitiesComplete] as const;
export type VoidActionKey = (typeof otherActions)[number];

export type InlineGuideActionKey =
    | NavigationActionKey
    | WithCallbackActionKey
    | WithImmediateCallbackActionKey
    | VoidActionKey;

export function isInlineGuideActionKey(value: string): value is InlineGuideActionKey {
    return (
        navigationActions.includes(value as NavigationActionKey) ||
        withCallbackActions.includes(value as WithCallbackActionKey) ||
        withImmediateCallbackActions.includes(value as WithImmediateCallbackActionKey) ||
        otherActions.includes(value as VoidActionKey)
    );
}

export function isNavigationGuideActionKey(value: string): value is InlineGuideActionKey {
    return navigationActions.includes(value as NavigationActionKey);
}

export function isImmediateGuideActionKey(value: string): value is InlineGuideActionKey {
    return withImmediateCallbackActions.includes(value as WithImmediateCallbackActionKey);
}


