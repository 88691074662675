<template>
    <app-page>
        <app-page-header title="Personal strengths" @show-tutorial="onShowTutorial" />
        <PersonalStrengthsGuideModalExplanation
            v-model="showTutorial"
            :navigate-back="tutorialNavigateBack"
        />
        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Identify your personal strengths">
                    Your personal strengths can sometimes be called your 'soft skills'. They are
                    different to your Learned & Technical skills in that they relate to how you work
                    and interact with others.
                    <br />
                    <br />
                    They often include traits and abilities that influence how you communicate,
                    collaborate, and manage your emotions. E.g playful or thoughtful or empathetic.
                </app-page-content-intro>
            </template>

            <PersonalStrengthsContent />
        </app-page-content>

        <PersonalStrengthsGuide />

        <app-page-footer>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions :dummy="true" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.PersonalStrengths" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import PersonalStrengthsGuideModalExplanation from '@/components/canvas/personal-strengths/guide-modal/PersonalStrengthsGuideModalExplanation.vue';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import PersonalStrengthsContent from '@/components/canvas/personal-strengths/PersonalStrengthsContent.vue';
    import PersonalStrengthsGuide from '@/components/canvas/personal-strengths/PersonalStrengthsGuide.vue';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { InlineGuideSection } from '@/store/inline-guide/types';
    import { useCurriculumSubscribeChangesStore } from '@/store/syncing/curriculumSyncingStore';
    import { useTutorial } from '@/store/useTutorial';

    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = usePersonalStrengthsStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PersonalStrengths);

    const curriculumPollingStore = useCurriculumSubscribeChangesStore();

    onMounted(() => {
        if (!store.confirmed.length) {
            showTutorial.value = true;
        }

        curriculumPollingStore.addSubscription('inferred-personal-strengths', {
            fn: subscription,
            onComplete: async () => {
                await useInlineGuideStore().getMessage(InlineGuideSection.PersonalStrength);
            },
        });
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalStrengths);

        curriculumPollingStore.removeSubscription('inferred-personal-strengths');
    });

    // Subscribe to uploads on CV, and pull inferred skills and messages
    const currentInferredSkills = store.allInferred.length;

    const subscription = async () => {
        const newInferredSkillsLength = store.allInferred.length;
        console.info(
            'Running Personal Strength subscription to curriculum changes.\n' +
                `Current amount of inferred skills: ${currentInferredSkills}. New skills ${newInferredSkillsLength}`,
        );

        if (newInferredSkillsLength > currentInferredSkills) {
            // THIS IS A LOT OF COUPLING WITH KNOWLEDGE OF THE APP. WE NEED A BETTER MECHANISM
            // Execute only once at the end of the subscription
            console.info(
                `New inferred personal strengths. Exiting subscription successfully. Fetching context skills inline message...`,
            );
            // early return
            return false;
        }

        console.info(`Fetching inferred personal strengths inside subscription...`);
        await store.syncInferredSkills();

        // stop the polling if there are new inferred skills
        return true;
    };
</script>
