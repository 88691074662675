import type { InlineGuideMessage } from '@/api/types/inlineGuideMessage';
import { defineStore } from 'pinia';
import { DataState, makeDataState, makeDataStateGetters } from '@/store/common/dataState';
import { fetchInlineGuide } from '@/services/inline-guide/service';
import { useUsersStore } from '@/store/user/store';
import { useCanvasStore } from '@/store/canvas/store';
import type { InlineGuideActionKey } from '@/store/inline-guide/types';
import { InlineGuideSection, isInlineGuideActionKey } from '@/store/inline-guide/types';
import { assertThat } from '@/store/verify';
import type { PersistenceOptions } from 'pinia-plugin-persistedstate';

export type InlineGuideStoreState = {
    message: InlineGuideMessage | null;
    state: DataState;
    error: any | null;
    seenMessages: { [key: string]: number };
};

export const useInlineGuideStore = defineStore('inline-guide-store', {
    state: (): InlineGuideStoreState => ({
        message: null,
        seenMessages: {},
        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        content(state): string {
            return this.message?.content ?? '';
        },
        title(state): string {
            return this.message?.title ?? '';
        },
        subtitle(state): string {
            return this.message?.subtitle ?? '';
        },
        action(state): InlineGuideActionKey | null {
            const action = this.message?.action;
            if (action) {
                assertThat(isInlineGuideActionKey(action), `Invalid action key ${action}`);
                return action;
            }

            return null;
        },
        actionContext(state): string {
            return this.message?.actionContext ?? '';
        },
        hasMessage(state): boolean {
            return this.message != null;
        },
    },
    actions: {
        clearStorage(): void {
            // Resets the store and clears the local storage
            this.$reset();
        },
        removeMessage(): void {
            this.message = null;
        },
        async getMessage(section: InlineGuideSection): Promise<void> {
            this.removeMessage();

            // Delay Fetching of the story inline guide, to give time to the request in the browser to reach the server
            // TODO: this is a hack and probably will mask the issues for a short period of time and inconsistently.
            await new Promise((resolve) => setTimeout(resolve, 2000));

            const context = await useCanvasStore().makeContext();
            const { accessToken } = await useUsersStore().makeContext();

            const inlineGuideMessage = await fetchInlineGuide(
                context.canvasId,
                section,
                accessToken,
            );

            if (!inlineGuideMessage) {
                return;
            }

            if (
                inlineGuideMessage.strategy === 'only-once' &&
                this.seenMessages[inlineGuideMessage.uuid] !== undefined
            ) {
                // Skip message if it's already seen
                console.log(
                    `Skipping seen message with uuid: '${inlineGuideMessage.uuid}' and strategy: '${
                        inlineGuideMessage.strategy
                    }' for user: ${useUsersStore().user?.id}`,
                );
                return;
            }

            this.message = {
                ...inlineGuideMessage,
            };

            console.log(
                `Persisting seen message with uuid: '${
                    inlineGuideMessage.uuid
                }' for user: ${useUsersStore().user?.id}`,
            );

            // Persist to seen messages
            const times = this.seenMessages[inlineGuideMessage.uuid];
            this.seenMessages[inlineGuideMessage.uuid] = times !== undefined ? times + 1 : 0;
        },
    },
    persist: {
        key: 'inlineGuideStore', // Default key
        storage: {
            getItem(key: string) {
                const userId = useUsersStore().user?.id;
                if (!userId) return null; // No user, no storage
                const userScopedKey = `${key}:${userId}`;
                const storedValue = localStorage.getItem(userScopedKey);

                if (storedValue) {
                    try {
                        // Parse the value and return the persisted data
                        return JSON.parse(storedValue);
                    } catch (error) {
                        console.error(
                            `Error parsing persisted state for key: ${userScopedKey}`,
                            error,
                        );
                        return null;
                    }
                }
                return null;
            },
            setItem(key: string, value) {
                const userId = useUsersStore().user?.id;
                if (!userId) return; // No user, no storage
                const userScopedKey = `${key}:${userId}`;
                localStorage.setItem(userScopedKey, value);
            },
            removeItem(key: string) {
                const userId = useUsersStore().user?.id;
                if (!userId) return; // No user, no storage
                const userScopedKey = `${key}:${userId}`;
                localStorage.removeItem(userScopedKey);
            },
        },
        pick: ['seenMessages'], // Persist only seenMessages
    } as PersistenceOptions<InlineGuideStoreState>,
});
