<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Challenges"
        action-text="Start"
        :steps="1"
        :navigate-back="navigateBack"
    >
        <template #title="{ step }">
            <span v-if="step === 1">What do we mean by challenges...</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    If you could eliminate problems or blockers from your working day, what would be
                    your top priority?
                </p>
                <p>
                    Think about times when you come under pressure, or feel under-prepared in your
                    working day. What are the common themes or problems you are experiencing
                    regularly?
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
        navigateBack?: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
