import { ref } from 'vue';

export function useTutorial() {
    const showTutorial = ref(false);
    const tutorialNavigateBack = ref(true);

    // Function to show the tutorial without navigation back
    const onShowTutorial = () => {
        showTutorial.value = true;
        tutorialNavigateBack.value = false;
    };

    return {
        showTutorial,
        tutorialNavigateBack,
        onShowTutorial,
    };
}
