<template>
    <v-card
        min-width="260"
        class="rounded-lg pa-5 challenge-action-card overflow-visible"
        elevation="0"
        :class="contentClass"
    >
        <v-card-item class="pa-0" @click="expanded = !expanded">
            <div class="text-heading-h4 cursor-pointer">
                {{ item.title }}
            </div>
            <div class="mt-2">
                <app-label :type="item.action_type" />
            </div>
            <template #append>
                <span class="activity-card-completed-label" v-if="isCompleted">
                    <v-chip
                        class="text-custom-neutral-black text-paragraph-xs font-weight-600 app-label bg-primary"
                        prepend-icon="mdi-check"
                    >
                        Completed
                    </v-chip>
                </span>
                <span class="activity-card-title-expand cursor-pointer">
                    <app-fa-icon v-if="!isExpanded" class="expand-button" icon="chevron-down" />
                    <app-fa-icon v-else icon="chevron-up" />
                </span>
            </template>
        </v-card-item>
        <v-card-text class="pa-0" v-if="isExpanded">
            <v-row no-gutters>
                <v-col :cols="12" class="pa-0">
                    <div class="my-3">
                        <span class="text-custom-neutral-grey-700 text-paragraph-sm w-100">
                            {{ item.description }}
                        </span>
                    </div>
                    <div
                        class="text-custom-neutral-grey-700 text-paragraph-sm w-100 mt-4"
                        v-if="item.long_description"
                    >
                        <div v-html="formattedLongDescription" id="htmlContainer"></div>
                    </div>

                    <v-card-text class="pa-0" v-if="links.length">
                        <div class="mt-5">
                            <v-divider class="my-5" />
                            <ul class="ml-4">
                                <li v-for="link in links" :key="link.url">
                                    <a :href="link.url" target="_blank">{{ link.title }}</a>
                                </li>
                            </ul>
                        </div>
                    </v-card-text>

                    <v-divider class="mt-3 mb-5" />
                    <div class="">
                        <div class="text-paragraph-xs">Recommended approach:</div>
                        <div class="text-paragraph-md text-custom-neutral-black">
                            {{ formattedFrequency }}
                        </div>

                        <div class="mt-4">
                            <div class="text-paragraph-xs">Intended due date:</div>
                            <div class="text-paragraph-md text-custom-neutral-black">
                                {{ formattedDueDate }}
                            </div>
                        </div>
                        <app-button
                            v-if="!isCompleted"
                            class="mt-5 text-none text-custom-grey-900 font-weight-600 opacity-1"
                            variant="flat"
                            height="40"
                            color="primary"
                            @click="emit('mark-as-completed')"
                        >
                            Mark completed
                        </app-button>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import {
        type CurrentChallengeAction,
        CurrentChallengeActionState,
    } from '@/api/types/plan/currentChallengeAction';
    import { formatFrequency } from '@/components/pathway/single-challenge/sequencing/formatFrequency';
    import { format } from 'date-fns';
    import { parseLinks } from '@/components/pathway/single-challenge/sequencing/parseLinks';

    const props = defineProps<{
        item: CurrentChallengeAction;
    }>();

    const emit = defineEmits(['mark-as-completed']);

    const expanded = ref<boolean>(false);
    const isExpanded = computed(() => {
        return expanded.value;
    });

    watch(
        () => props.item.state,
        () => {
            expanded.value = props.item.state !== CurrentChallengeActionState.Completed;
        },
        { immediate: true },
    );

    const contentClass = computed(() => {
        if (isCompleted.value) {
            return 'action-completed';
        } else {
            return 'action-not-completed';
        }
    });

    const isCompleted = computed(() => {
        return props.item.state === CurrentChallengeActionState.Completed;
    });

    const onLearnMoreClick = () => {
        window.open('http://google.com/search?q=' + props.item.title, '_blank');
    };

    const formattedFrequency = computed(() => {
        return formatFrequency(
            props.item.frequency,
            props.item.duration_min,
            props.item.duration_max,
        );
    });

    const formattedLongDescription = computed(() => {
        return (props.item.long_description ?? '').replace(/\n/g, '<br>');
    });

    const links = computed(() => {
        return parseLinks(props.item.links);
    });

    const formattedDueDate = computed(() => {
        if (props.item.due_date) {
            return format(new Date(props.item.due_date), 'MMMM dd, yyyy');
        }

        return '';
    });
</script>

<style scoped lang="scss">
    .challenge-action-card {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }

    .action-item-action-type {
        position: absolute;
        right: 20px;
    }

    .action-item-frequency {
        position: absolute;
        bottom: 20px;
    }

    .activity-card-title-expand {
        position: absolute;
        right: 20px; // compensates card padding
        top: var(--top-button);
    }

    .activity-card-completed-label {
        position: absolute;
        right: 16px;
        top: -16px;
    }

    .action-completed,
    .action-not-completed {
        margin-top: 16px;
    }
</style>
