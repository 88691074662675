<template>
    <v-container class="pa-0">
        <v-row v-if="!yourSkills.length && !store.isAddingNew" no-gutters>
            <PersonalStrengthsListEmptyCard />
        </v-row>

        <div no-gutters v-if="yourSkills.length" class="w-100 text-heading-h3 mb-4">
            Your personal strengths
        </div>
        <v-row no-gutters>
            <v-col cols="12" class="mb-4" v-for="(item, index) in yourSkills" :key="index">
                <PersonalStrengthsListItemNew
                    :item="item"
                    @edit-click="onEdit"
                    @delete-click="onDelete"
                    @change-type="onChangeType"
                />
            </v-col>
        </v-row>
        <v-row no-gutters justify="start">
            <v-col cols="12" class="mb-4">
                <PersonalStrengthListItemForm v-if="store.isAddingNew" />
                <app-plus-button v-else @click="store.addPersonalStrength"
                    >{{
                        yourSkills.length
                            ? 'Add another strength'
                            : 'Add your first personal strength'
                    }}
                </app-plus-button>
            </v-col>
        </v-row>

        <v-divider class="my-8" v-if="inferredSkills.length" />
        <div no-gutters v-if="inferredSkills.length" class="w-100 text-heading-h3 mb-4">
            Suggested personal strengths from your CV:
        </div>
        <v-row no-gutters>
            <v-col cols="12" class="mb-4" v-for="(item, index) in inferredSkills" :key="index">
                <InferredPersonalStrengthListItem
                    :item="item as InferredCanvasSkill"
                    @edit-click="onEdit"
                    @change-type="onChangeType"
                />
            </v-col>
        </v-row>
    </v-container>

    <ActivityNotification
        :value="store.confirmed.length"
        :area="UserActivityArea.PersonalStrengths"
    />
</template>

<script setup lang="ts">
    import { type Skill } from '@/store/skills/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import ActivityNotification from '@/components/canvas/notifications/ActivityNotification.vue';
    import { computed } from 'vue';
    import {
        type InferredCanvasSkill,
        InferredRecordState,
        isCanvasSkill,
        isInferredCanvasSkill,
    } from '@/api/types/canvas/skills';
    import PersonalStrengthsListItemNew from '@/components/canvas/personal-strengths/PersonalStrengthsListItemNew.vue';
    import PersonalStrengthListItemForm from '@/components/canvas/personal-strengths/PersonalStrengthListItemForm.vue';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import type { SkillType } from '@/store/skills/types';
    import PersonalStrengthsListEmptyCard from '@/components/canvas/personal-strengths/PersonalStrengthsListEmptyCard.vue';
    import InferredPersonalStrengthListItem from '@/components/canvas/personal-strengths/InferredPersonalStrengthListItem.vue';

    const store = usePersonalStrengthsStore();

    const onChangeType = ({ item, type }: { item: Skill; type: string }) => {
        store.updatePersonalStrengthType(item, type as SkillType);
    };

    const onEdit = (item: Skill) => {
        store.setEdit(item);
    };

    const onDelete = async (item: Skill) => {
        await store.removePersonalStrength(item);
    };

    const yourSkills = computed(() => {
        return store.confirmed;
    });

    const inferredSkills = computed(() => {
        return store.all.filter((skill) => {
            if (isInferredCanvasSkill(skill)) {
                return skill.state === InferredRecordState.Inferred;
            }
        });
    });
</script>

<style scoped lang="scss">
    .skills-divider {
        background: rgba(251, 134, 37, 1);
    }
</style>
