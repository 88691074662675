<template>
    <v-container class="pa-0">
        <v-row v-if="!yourSkills.length && !store.isAddingNew" no-gutters>
            <SkillsListEmptyCard />
        </v-row>

        <div no-gutters v-if="yourSkills.length" class="w-100 text-heading-h3 mb-4">
            Your skills
        </div>
        <v-row no-gutters>
            <v-col cols="12" class="mb-4" v-for="(item, index) in yourSkills" :key="index">
                <SkillsListItem :item="item" @edit-click="onEdit" @change-type="onChangeType" />
            </v-col>
        </v-row>
        <v-row no-gutters justify="start">
            <v-col cols="12" class="mt-4">
                <SkillListItemForm
                    v-if="store.isAddingNew"
                    :item="store.current as unknown as CanvasSkill"
                />
                <app-plus-button v-else @click="store.addSkill(null)">
                    {{ yourSkills.length ? 'Add another skill' : 'Add your first skill' }}
                </app-plus-button>
            </v-col>
        </v-row>

        <v-divider class="my-8" v-if="inferredSkills.length" />
        <div no-gutters v-if="inferredSkills.length" class="w-100 text-heading-h3 mb-4">
            Suggested skills from your CV:
        </div>
        <v-row no-gutters>
            <v-col cols="12" class="mb-4" v-for="(item, index) in inferredSkills" :key="index">
                <InferredSkillsListItem
                    :item="item as InferredCanvasSkill"
                    @edit-click="onEdit"
                    @change-type="onChangeType"
                />
            </v-col>
        </v-row>
    </v-container>

    <ActivityNotification :value="store.confirmedSkills.length" :area="UserActivityArea.Skills" />
</template>

<script setup lang="ts">
    import { type Skill, useSkillsStore } from '@/store/skills/store';
    import SkillsListItem from '@/components/canvas/skills/SkillsListItem.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import ActivityNotification from '@/components/canvas/notifications/ActivityNotification.vue';
    import { computed } from 'vue';
    import {
        type CanvasSkill,
        type InferredCanvasSkill,
        InferredRecordState,
        isCanvasSkill,
        isInferredCanvasSkill,
    } from '@/api/types/canvas/skills';
    import SkillListItemForm from '@/components/canvas/skills/SkillListItemForm.vue';
    import { verify } from '@/store/verify';
    import type { SkillType } from '@/store/skills/types';
    import SkillsListEmptyCard from '@/components/canvas/skills/SkillsListEmptyCard.vue';
    import InferredSkillsListItem from '@/components/canvas/skills/InferredSkillsListItem.vue';

    const store = useSkillsStore();

    const onChangeType = ({ item, type }: { item: Skill; type: string }) => {
        store.updateSkillType(item, verify(type as SkillType, 'no type'));
    };

    const onEdit = (item: Skill) => {
        store.setEdit(item);
    };

    const yourSkills = computed(() => {
        return store.allSkills.filter((skill) => {
            return isCanvasSkill(skill);
        });
    });

    const inferredSkills = computed(() => {
        return store.allSkills.filter((skill) => {
            if (isInferredCanvasSkill(skill)) {
                return skill.state === InferredRecordState.Inferred;
            }
        });
    });
</script>

<style scoped lang="scss">
    .skills-divider {
        background: rgba(251, 134, 37, 1);
    }
</style>
