<template>
    <app-page>
        <app-page-header title="Challenge pathway" @show-tutorial="onShowTutorial" />
        <ChallengePathTutorial v-model="showTutorial" :navigate-back="tutorialNavigateBack" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Select a challenge">
                    Choose a challenge so that we can build a plan of activities to help you solve
                    it.

                    <br />
                    <br />
                    We suggest you start with something easy.
                </app-page-content-intro>
            </template>

            <v-container class="pa-0" v-if="store.inProgressChallenges.length">
                <div class="text-h5 font-weight-bold">In progress</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.inProgressChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8" v-if="store.notStartedChallenges.length">
                <div class="text-h5 font-weight-bold">Not started</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.notStartedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8" v-if="store.completedChallenges.length">
                <div class="text-h5 font-weight-bold">Completed</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.completedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8" v-if="store.deactivatedChallenges.length">
                <v-expansion-panels>
                    <v-expansion-panel elevation="0" class="">
                        <v-expansion-panel-title class="pa-0 text-paragraph-md font-weight-600">
                            <div class="text-h5 font-weight-bold align-center d-flex w-100">
                                Archived
                                <v-tooltip
                                    location-strategy="connected"
                                    content-class="bg-white text-neutral-black text-paragraph-md pa-5 elevation-3"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-icon v-bind="props" size="20" class="ml-2"
                                            >mdi-help-circle
                                        </v-icon>
                                    </template>
                                    These pathways have been archived in the past. They can be
                                    unarchived at any time.
                                </v-tooltip>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="pa-0">
                            <PathwayChallengeListItem
                                v-for="(item, index) in store.deactivatedChallenges"
                                :key="index"
                                :item="item"
                                class="mb-2"
                                :is-editable="true"
                                @click="onArchiveClick(item)"
                            />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-container>
        </app-page-content>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasNavOneButton text="Back to story" :to="storyRoute" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page>
    <ChallengePathGuide />

    <SingleChallengePathUnarchive
        v-model="showArchive"
        :item="archiveItem!"
    ></SingleChallengePathUnarchive>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    import { UserActivityArea } from '@/api/types/userActivity';
    import CanvasNavOneButton from '@/components/canvas/CanvasNavOneButton.vue';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { storyRoute } from '@/router/routes';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';
    import { useActivityStore } from '@/store/activity/store';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import PathwayChallengeListItem from '@/components/pathway/PathwayChallengeListItem.vue';
    import ChallengePathGuide from '@/components/pathway/ChallengePathGuide.vue';
    import { useTutorial } from '@/store/useTutorial';
    import SingleChallengePathUnarchive from '@/components/pathway/single-challenge/SingleChallengePathUnarchive.vue';
    import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';

    const store = useChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    await store.load();

    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const showArchive = ref(false);
    const archiveItem = ref<CanvasCurrentChallenge | null>(null);
    const onArchiveClick = (item: CanvasCurrentChallenge) => {
        archiveItem.value = item;
        showArchive.value = true;
    };

    onMounted(async () => {
        const noChallengeWithSuggestions = store.activeChallenges.every(
            (challenge) => !challenge.suggested_actions.length,
        );
        const noChallengesStarted =
            store.notStartedChallenges.length &&
            !store.inProgressChallenges.length &&
            !store.completedChallenges.length;
        if (!store.hasActiveChallenges || (noChallengesStarted && noChallengeWithSuggestions)) {
            showTutorial.value = true;
        }

        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });
</script>
