<template>
    <v-card v-if="isUpdating" variant="plain" height="400" class="w-100">
        <Loader
            class="h-100 fill-height"
            title="We are setting up your story..."
            subtitle="This could take a few seconds..."
        />
    </v-card>
    <app-page v-else-if="show">
        <app-page-header title="Account setup" :hide-tutorial="true" />
        <WelcomeLanding v-if="step === Steps.Landing" @next-click="next" />
        <app-page-content v-else>
            <template v-slot:intro>
                <app-page-content-intro title="Let’s get you set-up!">
                    <div class="text-paragraph-md my-5">
                        We're going to ask you for some information on you now, that will help us to
                        personalise your journey, and shape future opportunities we present to you.
                        Please complete as accurately as you can.
                    </div>
                </app-page-content-intro>
            </template>
            <WelcomeStepFirst v-if="step === Steps.SetName"
                v-model:termsAndConditionModel="termsAndConditionModel"
                v-model:privacyModel="privacyModel"
            />
            <WelcomeStepSecond v-else-if="step === Steps.Curriculum" />
            <WelcomeStepThird v-else-if="step === Steps.GetStarted" />
        </app-page-content>
        <app-page-footer>
            <template #actions>
                <SaveProgressButtons
                    close-text="Back"
                    :close-hide="step === Steps.Landing"
                    @on-back="previous"
                    submit-text="Continue"
                    :submit-disabled="isSubmitDisabled"
                    @on-submit="next"
                >
                </SaveProgressButtons>
            </template>
        </app-page-footer>
    </app-page>

    <v-dialog v-model="showContinueWithoutCVDialog" max-width="400">
        <v-card class="pa-6">
            <v-card-title class="px-0 text-heading-h3 text-wrap"
                >We recommend uploading your CV
            </v-card-title>
            <v-card-text class="px-0 text-paragraph-md">
                Uploading your CV is highly recommended as it helps you make the most out of
                Memorable Experiences and Skills activities. Would you like to stay and upload your
                CV?
            </v-card-text>
            <v-card-actions class="px-0">
                <app-button @click="onContinueWithoutCV">Skip</app-button>
                <v-spacer />
                <app-button
                    color="primary"
                    variant="flat"
                    @click="showContinueWithoutCVDialog = false"
                    >Yes, upload CV
                </app-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { computed, onBeforeMount, onMounted, ref } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { useRouter } from 'vue-router';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { homeRoute } from '@/router/routes';
    import { useActivityStore } from '@/store/activity/store';
    import { storeToRefs } from 'pinia';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useDisplay } from 'vuetify';
    import WelcomeLanding from '@/components/welcome/WelcomeLanding.vue';
    import WelcomeStepSecond from '@/components/welcome/WelcomeStepSecond.vue';
    import WelcomeStepFirst from '@/components/welcome/WelcomeStepFirst.vue';
    import WelcomeStepThird from '@/components/welcome/WelcomeStepThird.vue';
    import Loader from '@/components/common/Loader.vue';
    import SaveProgressButtons from '@/components/footer-actions/SaveProgressButtons.vue';
    import { phoneNumberRules } from '@/components/profile/rules';

    const store = useUserProfileStore();
    const activityStore = useActivityStore();

    const router = useRouter();

    const { smAndDown } = useDisplay();
    const { responsiveClass, isDesktop } = useIsDesktop();

    const termsAndConditionModel = ref(false);
    const privacyModel = ref(false);

    const isUpdating = ref(false);

    enum Steps {
        Landing = 0,
        SetName = 1,
        Curriculum = 2,
        GetStarted = 3,
    }

    const show = ref(false);
    const step = ref(Steps.Landing);
    const { userInputs } = storeToRefs(store);

    onBeforeMount(async () => {
        store.$reset();
    });

    onMounted(async () => {
        // TODO: HACK
        // Show the page after a short delay to allow the page to render
        setTimeout(() => {
            show.value = true;
        }, 100);
        store.init();

        // if (userStore.hasProfileBeenSet) {
        //     console.log(
        //         'Profile has already been set by user. Nothing to do on welcome page. Back to home',
        //     );
        //     await router.push(homeRoute);
        // }

        await activityStore.addEntryActivity(UserActivityArea.Welcome);
    });

    const previous = () => {
        step.value -= 1;
    };

    const showContinueWithoutCVDialog = ref(false);
    const onContinueWithoutCV = async () => {
        // order of operations is important here
        await next();
        showContinueWithoutCVDialog.value = false;
    };

    const next = async () => {
        switch (step.value) {
            case Steps.Landing:
            case Steps.SetName:
                step.value += 1;
                break;
            case Steps.Curriculum:
                if (!store.curriculumFile && !showContinueWithoutCVDialog.value) {
                    showContinueWithoutCVDialog.value = true;
                    return;
                }

                step.value += 1;
                break;
            case Steps.GetStarted:
                step.value += 1;
                await onCompleteAllWelcomeStepsClick();
                break;
        }
    };

    const onCompleteAllWelcomeStepsClick = async () => {
        isUpdating.value = true;

        const isUploadingCV = store.curriculumFile && store.isValidFileType;

        await store.save(true);

        // This is a hack to make the loader show for a bit longer when uploading a CV
        // and give some time to the API to process the file.
        // If quick enough it might have suggestions
        const timeout = (isUploadingCV ? 10 : 3) * 1000;
        setTimeout(() => {
            router.push(homeRoute);
            isUpdating.value = false;
        }, timeout);
    };

    const isSubmitDisabled = computed(() => {
        const inputs = userInputs.value;
        switch (step.value) {
            case Steps.SetName:
                return (
                    !inputs.first_name ||
                    !inputs.last_name ||
                    !inputs.email ||
                    !inputs.gender ||
                    !inputs.date_of_birth ||
                    phoneNumberRules(inputs.phone_number) !== true ||
                    !termsAndConditionModel.value ||
                    !privacyModel.value
                );
            case Steps.Curriculum:
                return (
                    !inputs.current_company ||
                    (store.curriculumFile && !store.isValidFileType) ||
                    !inputs.job_title ||
                    inputs.current_company_confidence_rating === null
                ); // # || !store.curriculumFile;
            case Steps.GetStarted:
                return (
                    !inputs.location ||
                    !inputs.visa_type ||
                    !inputs.primary_language
                );
            default:
                return false;
        }
    });
</script>

<style scoped lang="scss">
    :deep(.text-field-transparent .v-input__slot) {
        background: transparent !important;
        opacity: 1;
    }

    // This will add an extra margin, so when errors are displayed in the fields the fields do not collapse
    :deep(.v-messages__message) {
        margin-top: 8px;
        padding-left: 0;
        padding-bottom: 16px;
    }
</style>
