import {
    type CurrentChallengeAction,
    type SuggestedChallengeAction,
} from '@/api/types/plan/currentChallengeAction';

export enum CurrentChallengeState {
    /**
     * At this point the challenge has not being started.
     *
     * It could have suggested actions that have not yet been materialized yet into actions.
     */
    New = 'new',

    /**
     * At this point the challenge has already been started.
     * It should have actions that are not completed yet.
     */
    InProgress = 'in-progress',

    /**
     * The challenge has been completed and should be immutable.
     */
    Completed = 'completed',
}

export type CanvasCurrentChallenge = {
    id: number;
    canvas_id: number;
    description: string;
    challenge_path_id: number;
    created_at: string;

    /**
     * The actions should be empty if the challenge is in the `New` state.
     * TODO: add guard clause for this
     */
    actions: CurrentChallengeAction[];

    suggested_actions: SuggestedChallengeAction[];
    state: CurrentChallengeState;
    is_active: boolean;
};

export type CanvasCurrentChallengeCreateData = Pick<CanvasCurrentChallenge, 'description'>;
