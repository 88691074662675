import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useUsersStore } from '@/store/user/store';
import { executeUntil, TimeoutError } from '@/store/syncing/polling';

type SubscriberKey = 'inferred-skills' | 'inferred-personal-strengths';

/**
 * If true keeps running. If false stops the execution
 */
type SubscriptionFunction = () => Promise<boolean>;
export type SubscriptionCreate = {
    fn: SubscriptionFunction;

    /**
     * At the moment runs when success, or after the timeout of 1 minute. TODO: add different onComplete, onFail, etc
     */
    onComplete?: () => void;
};

type Subscription = {
    key: SubscriberKey;
    state: 'idle' | 'running' | 'error';
} & SubscriptionCreate;

type SubscriptionMap = Record<SubscriberKey, Subscription>;

/**
 * This is a global store that is mounted once, and listen for changes in the amount of linked to a user
 * Once it detects a change, it calls each of the subscriptions for a total of 1 minute.
 * Each page should add / stop its subscriptions.
 *
 * TODO: tidy up code
 * TODO: handle timeout in each subscription. E.g.: If not inferred skills were found after 1 minute,
 *       call inline guide message
 */
export const useCurriculumSubscribeChangesStore = defineStore('curriculum-polling', () => {
    const pollingStatus = ref<'idle' | 'polling' | 'completed' | 'error'>('idle');
    const error = ref<Error | null>(null);
    const usersStore = useUsersStore();
    const subscriptions = ref<SubscriptionMap>({} as SubscriptionMap); // Holds subscriber functions
    let watcherCleanup: (() => void) | null = null; // To manage the watcher lifecycle

    const addSubscription = (key: SubscriberKey, options: SubscriptionCreate) => {
        subscriptions.value[key] = {
            key,
            state: pollingStatus.value === 'polling' ? 'running' : 'idle',
            ...options,
        };
        console.info(`Subscription add: ${key}`);
    };

    const removeSubscription = (key: SubscriberKey) => {
        delete subscriptions.value[key];
        console.info(`Subscription removed: ${key}`);
    };

    const clearSubscriptions = () => {
        subscriptions.value = {} as SubscriptionMap;
        console.info('All subscriptions cleared.');
    };

    const executeSubscriptionsOnCurriculum = async () => {
        pollingStatus.value = 'polling';

        for (const [key, subscription] of Object.entries(subscriptions.value)) {
            console.log(`Start marking subscription with key: '${key}' as 'running'`);
            subscription.state = 'running';
        }

        try {
            await executeUntil(
                async () => {
                    for (const [key, subscription] of Object.entries(subscriptions.value)) {
                        try {
                            console.log(`Executing subscription with key: '${key}...'`);
                            const result = await subscription.fn();

                            if (result) {
                                console.info(
                                    `Subscription with key: '${key}' returned true. Continue...`,
                                );
                            } else {
                                if (subscription.state === 'running' && subscription.onComplete) {
                                    console.info(
                                        `Subscription with key: '${key}' complete. Running onComplete...`,
                                    );
                                    await subscription.onComplete();
                                }

                                console.info(
                                    `Subscription with key: '${key}' returned false. Stopping...`,
                                );

                                subscription.state = 'idle';
                            }
                        } catch (err) {
                            console.error('Error executing subscription:', err);
                        }
                    }

                    // for now keep running
                    return false;
                },
                { subscriptionDuration: 60000 }, // 1-minute timeout
            );
            pollingStatus.value = 'completed';
        } catch (err) {
            if (err instanceof TimeoutError) {
                //  At the moment the timeout is always thrown and expected. Nothign to do
                console.warn('Executing subscriptions reach duration. Stopping....');

                for (const [key, subscription] of Object.entries(subscriptions.value)) {
                    if (subscription.state === 'running' && subscription.onComplete) {
                        console.info(
                            `Subscription with key: '${key}' did not reach complete state. Running onComplete...`,
                        );
                        await subscription.onComplete();
                    }
                    subscription.state = 'idle';
                }
            } else {
                console.error('Polling error:', err);
                error.value = err as Error;
                pollingStatus.value = 'error';
            }
        }
    };

    const start = () => {
        if (watcherCleanup) {
            console.warn('Subscription watcher is already active.');
            return;
        }

        watcherCleanup = watch(
            () => usersStore.user?.curriculums,
            async (newCurriculums, oldCurriculums) => {
                if (!newCurriculums || !oldCurriculums) return;

                const newCurriculum = newCurriculums.find(
                    (curriculum) =>
                        !oldCurriculums.some((oldCurriculum) => oldCurriculum.id === curriculum.id),
                );

                if (newCurriculum) {
                    console.info('New curriculum detected. Starting executing subscription...');
                    await executeSubscriptionsOnCurriculum();
                }
            },
            { deep: true },
        );

        console.info('Curriculum global watcher started.');
    };

    const stop = () => {
        if (watcherCleanup) {
            watcherCleanup();
            watcherCleanup = null;
            console.info('Curriculum global watcher stopped.');
        }
    };

    return {
        pollingStatus,
        error,
        start,
        stop,
        addSubscription,
        removeSubscription,
        clearSubscriptions,
    };
});
