import { defineStore } from 'pinia';
import { makeDataState } from '@/store/common/dataState';
import { useAppErrorStore } from '@/store/appErrorStore';
import { useCanvasStore } from '@/store/canvas/store';
import { fetchOrCreateCanvasCurrentPlan } from '@/services/plan/current/service';
import { HttpStatusCode } from 'axios';
import type { ChallengePathwayStoreState } from '@/store/challenge-path/types';
import {
    type CanvasCurrentChallenge,
    CurrentChallengeState,
} from '@/api/types/canvas/currentChallenge';
import type { CanvasCurrentPlan } from '@/api/types/plan/currentPlan';
import { activatePathwayChallenge } from '@/services/plan/current/challenge/service';

export const useChallengePathStore = defineStore({
    id: 'challenge-path',
    state: (): ChallengePathwayStoreState => ({
        _plan: null,
        ...makeDataState(),
    }),
    getters: {
        hasActiveChallenges(): boolean {
            return this.activeChallenges.length > 0;
        },
        activeChallenges(): CanvasCurrentChallenge[] {
            return this._plan?.challenges.filter((c) => c.is_active) ?? [];
        },
        deactivatedChallenges(): CanvasCurrentChallenge[] {
            return this._plan?.challenges.filter((c) => !c.is_active) ?? [];
        },
        inProgressChallenges(): CanvasCurrentChallenge[] {
            return this.activeChallenges.filter(
                (challenge) => challenge.state === CurrentChallengeState.InProgress,
            );
        },
        notStartedChallenges(): CanvasCurrentChallenge[] {
            return this.activeChallenges.filter(
                (challenge) => challenge.state === CurrentChallengeState.New,
            );
        },
        completedChallenges(): CanvasCurrentChallenge[] {
            return this.activeChallenges.filter(
                (challenge) => challenge.state === CurrentChallengeState.Completed,
            );
        },
    },
    actions: {
        async load() {
            console.info('Loading current plan...');
            await useAppErrorStore().catchErrors(async () => {
                const { canvas, canvasId, accessToken } = await useCanvasStore().makeContext();
                const [plan, response] = await fetchOrCreateCanvasCurrentPlan(canvas, accessToken);

                if (response.status === HttpStatusCode.Created) {
                    // Manually trigger a reload of the user resource, to keep FKs updates
                    await useCanvasStore().synchroniseCanvas(canvasId, accessToken);
                } else {
                    console.log('No need to synchronise user');
                }

                this._setPlan(plan);
            });
        },
        async activateChallenge(item: CanvasCurrentChallenge) {
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                await activatePathwayChallenge(canvasId, item.id, accessToken);
            });
        },
        _setPlan: function (plan: CanvasCurrentPlan | null) {
            this._plan = plan
                ? {
                      ...plan,
                      challenges: (plan?.challenges ?? []).map((challenge) => {
                          return {
                              ...challenge,
                          };
                      }),
                  }
                : null;
        },
    },
});
