<template>
    <v-container class="pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <CareerHistoryTimeline v-if="store.timeline.length" />
                <CareerHistoryEmptyCard v-else-if="!store.isCreating" class="w-100" />
            </v-col>
        </v-row>

        <v-row no-gutters class="pa-0">
            <v-col cols="12">
                <UserJobForm v-if="userJobStore.isCreating" :deletable="true" />
                <app-plus-button v-else class="font-weight-medium" @click="onAddNew">
                    {{
                        store.timeline.length
                            ? 'Add another job'
                            : 'Add your first job'
                    }}
                </app-plus-button>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useUserJobStore } from '@/store/user-job/store';
    import UserJobForm from '@/components/canvas/user-jobs/UserJobForm.vue';
    import { useDisplay } from 'vuetify';
    import CareerHistoryTimeline from '@/components/profile/career-history/CareerHistoryTimeline.vue';
    import CareerHistoryEmptyCard from '@/components/profile/career-history/CareerHistoryEmptyCard.vue';

    const store = useUserJobStore();
    const userJobStore = useUserJobStore();

    const { lgAndUp } = useDisplay();

    const onAddNew = () => {
        store.addNew();
    };
</script>
