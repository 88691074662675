<template>
    <v-row no-gutters>
        <LearnedExperienceTimeline v-if="store.timelineJobs.length" />
        <LearnedExperiencesEmptyCard v-else class="w-100" />
    </v-row>

    <ActivityNotification
        :value="store.learnedExperiences.length"
        :area="UserActivityArea.LearnedExperiences"
    />
</template>

<script setup lang="ts">
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceTimeline from '@/components/canvas/learned-experiences/LearnedExperienceTimeline.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import ActivityNotification from '@/components/canvas/notifications/ActivityNotification.vue';
    import LearnedExperiencesEmptyCard from '@/components/canvas/learned-experiences/LearnedExperiencesEmptyCard.vue';

    const store = useLearnedExperiencesStore();
</script>
