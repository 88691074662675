<template>
    <StoryNextAreaCard
        :color="color"
        :title="props.section.title"
        :description="props.section.description"
        :image="props.section.image"
        @next-click="emit('click')"
    />
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { StorySectionId, type StorySectionItem } from '@/store/story/types';
    import StoryNextAreaCard from '@/components/story/StoryNextAreaCard.vue';

    const props = defineProps<{
        section: StorySectionItem;
    }>();

    const emit = defineEmits(['click']);

    const color = computed(() => {
        switch (props.section.id) {
            case StorySectionId.WhatIOffer:
                return 'secondary-blue';
            case StorySectionId.WhoIAm:
                return 'secondary-purple';
            case StorySectionId.WhatIAmWorkingOn:
                return 'secondary-pink';
            default:
                throw new Error('Invalid section type');
        }
    });
</script>
