import type { ApiResponse } from '@/services/apiClient';
import { HttpStatusCode } from 'axios';

export async function fetch<T extends any>(
    key: string,
    get: () => Promise<ApiResponse<T>>,
): Promise<T> {
    console.info(`[${key}]: Fetching...`);
    const response = await get();

    if (response.data) {
        console.info(`[${key}] fetched....`);
        return response.data;
    } else {
        throw new Error(response.error?.message);
    }
}

export async function fetchAllowNull<T extends any>(
    key: string,
    get: () => Promise<ApiResponse<T>>,
): Promise<T | null> {
    console.info(`[${key}]: Fetching (allow null)...`);
    const response = await get();

    if (response.data) {
        console.info(`[${key}] fetched....`);
        return response.data;
    } else {
        return null;
    }
}

export async function update<T extends any>(
    key: string,
    put: () => Promise<ApiResponse<void>>,
): Promise<T | void> {
    console.info(`[${key}]: Updating...`);

    const updateResponse = await put();

    if (updateResponse.data && updateResponse.status === HttpStatusCode.Ok) {
        console.info('Updated item successfully...');
        return updateResponse.data;
    } else if (updateResponse.status === HttpStatusCode.UnprocessableEntity) {
        console.info(
            'Likely we are missing some of the required data for the creation of this item...',
        );
        throw new Error(updateResponse.error?.message);
    } else if (updateResponse.error) {
        console.warn('Item failed to update...');
        throw new Error(updateResponse.error?.message);
    } else {
        console.warn('Could not update item');
    }
}

export async function create<T extends any>(
    key: string,
    post: () => Promise<ApiResponse<T>>,
): Promise<[T, ApiResponse<T>]> {
    console.info(`[${key}]: Creating...`);

    const createResponse = await post();

    if (createResponse.data && createResponse.status === HttpStatusCode.Created) {
        console.info('Item created successfully...');
        return [createResponse.data, createResponse];
    } else if (createResponse.status === HttpStatusCode.UnprocessableEntity) {
        console.info(
            'Likely we are missing some of the required data for the creation of this item...',
        );
        throw new Error(createResponse.error?.message);
    } else if (createResponse.error) {
        console.warn('Item failed to create...');
        throw new Error(createResponse.error?.message);
    }

    throw new Error('Could not create item');
}

/**
 * Method to use when creating an item that is asynchronously created.
 *
 * The server will return a 202 status code if the item is accepted for creation.
 * The client has to then poll the server to check if the item has been created.
 */
export async function createInBackground<T extends any>(
    key: string,
    post: () => Promise<ApiResponse<T>>,
): Promise<ApiResponse<T>> {
    console.info(`[${key}]: Creating (background)...`);

    const createResponse = await post();

    if (createResponse.status === HttpStatusCode.Accepted) {
        console.info('Item accepted successfully...');
        return createResponse;
    } else if (createResponse.status === HttpStatusCode.UnprocessableEntity) {
        console.info(
            'Likely we are missing some of the required data for the creation of this item...',
        );
        throw new Error(createResponse.error?.message);
    } else if (createResponse.error) {
        console.warn('Item failed to create...');
        throw new Error(createResponse.error?.message);
    }

    throw new Error('Could not create item');
}

export async function remove(key: string, fn: () => Promise<ApiResponse<void>>): Promise<void> {
    console.info(`[${key}]: Deleting...`);
    const response = await fn();

    if (response.data && response.status === HttpStatusCode.Ok) {
        console.info(`[${key}] deleted successfully...`);
        return response.data;
    } else if (response.status === HttpStatusCode.UnprocessableEntity) {
        console.info(`[${key}]: Likely we are missing some of the required data for the delete...`);
        throw new Error(response.error?.message);
    } else if (response.error) {
        console.warn(`[${key}] update failed to delete...`);
        throw new Error(response.error?.message);
    }

    throw new Error(`[${key}]: Could not delete`);
}
