<template>
    <v-timeline side="end" align="start">
        <v-timeline-item
            v-for="(timelineItem, index) in store.timelineJobs || []"
            :key="index"
            dot-color="primary"
            size="x-small"
            class="ml-0 rounded-4"
        >
            <UserJobTimelineCard :item="timelineItem" :editable="false" />
            <div class="ml-10 mr-4">
                <LearnedExperienceTimelineCard
                    v-for="(experience, _index) in timelineItem.experiences || []"
                    :item="experience"
                    class="my-4"
                    :index="_index"
                    :editable="editable"
                />
                <LearnedExperienceEditItem
                    class="my-4"
                    v-if="
                        store.isAddingNew &&
                        store.current &&
                        store.current?.user_job_id === timelineItem.id
                    "
                />
                <app-button
                    v-else
                    variant="flat"
                    color="primary"
                    class="font-weight-medium my-4"
                    @click="onAddNew(timelineItem)"
                    height="36"
                >
                    Add experience
                </app-button>
            </div>
        </v-timeline-item>
    </v-timeline>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceTimelineCard from '@/components/canvas/learned-experiences/LearnedExperienceTimelineCard.vue';
    import { useDisplay } from 'vuetify';
    import UserJobTimelineCard from '@/components/canvas/learned-experiences/UserJobTimelineCard.vue';
    import type { UserJob } from '@/api/types/userJob';
    import LearnedExperienceEditItem from '@/components/canvas/learned-experiences/LearnedExperienceEditItem.vue';

    const { name } = useDisplay();

    const props = withDefaults(
        defineProps<{
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useLearnedExperiencesStore();

    const onAddNew = (job: UserJob) => {
        store.addLearnedExperience(job);
    };

    const minWidth = computed(() => {
        switch (name.value) {
            case 'xs':
                return '350px';
            case 'sm':
                return '400px';
            case 'md':
                return '600px';
            case 'lg':
                return '850px';
            case 'xl':
                return '850px';
            default:
                return '850px';
        }
    });
</script>

<style lang="scss" scoped>
    :deep(.v-timeline-item__body) {
        // It seems the timeline item in vuetify is in some way buggy.
        // So adding a huge min-width makes it full width
        min-width: v-bind(minWidth);

        width: 100%;

        margin-left: -24px;
        margin-top: -4px;

        padding-inline-start: 0px !important;
    }

    :deep(.v-timeline-item__opposite) {
        padding: 0 !important;
    }

    .v-timeline-item:first-child :deep(.v-timeline-divider__dot) {
        //margin-top: 25px;
        .v-timeline-divider__before {
            display: none;
        }
    }

    :deep(..v-timeline-divider__dot) {
        box-shadow: unset;
    }

    :deep(.v-timeline-divider__before),
    :deep(.v-timeline-divider),
    :deep(.v-timeline-divider__after) {
        width: 2px;
        border: 1px dashed;
        border-color: rgb(var(--v-theme-primary));
        background-color: white !important;
        //border-color: linear-gradient(167deg, #FFC32B 0%, #FB8625 100%);

        //border-image-slice: 10;

        //background: linear-gradient(167deg, #FFC32B 0%, #FB8625 100%);
    }
</style>
