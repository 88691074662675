<template>
    <app-guide-modal-multiple-steps
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        section-name="Personality"
        action-text="Let's go"
        :steps="2"
        :navigate-back="navigateBack"
    >
        <template #title="{ step }">
            <span v-if="step === 1">Know yourself better!</span>
            <span v-else-if="step === 2">Myers Briggs</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Have you undertaken a Personality Test recently? We recommend you do one as it’s a rich source of insight into the way you are biologically wired.
                </p>
                <p>
                    Knowing your personality type can help navigate you to the work and teams that best suit your innate style.
                </p>
            </div>
            <div v-if="step === 2">
                <p>
                    We favour the Myers Briggs Type Indicator test as it’s one of the most scientifically robust.
                </p>
                <p>
                    The Myers Briggs Type Indicator categorizes individuals into one of 16 personality types based on preferences for introversion / extroversion, sensing / intuition, thinking / feeling, and judging / perceiving.
                </p>
            </div>
        </template>
    </app-guide-modal-multiple-steps>
</template>
<script setup lang="ts">
    import AppGuideModalMultipleSteps from '@/components/guide/modal/AppGuideModalMultipleSteps.vue';

    const props = defineProps<{
        modelValue: boolean;
        navigateBack?: boolean;
    }>();

    const emit = defineEmits(['update:modelValue']);
</script>
