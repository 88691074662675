<template>
    <SkillListItemForm v-if="isEdit" :item="item" />
    <SkillsListItemRead v-else :item="item" @edit-click="emit('edit-click', item)" />
</template>

<script setup lang="ts">
    import { useSkillsStore } from '@/store/skills/store';
    import { computed } from 'vue';
    import { type CanvasSkill } from '@/api/types/canvas/skills';
    import SkillListItemForm from '@/components/canvas/skills/SkillListItemForm.vue';
    import SkillsListItemRead from '@/components/canvas/skills/SkillsListItemRead.vue';

    const props = defineProps<{
        item: CanvasSkill;
    }>();

    const emit = defineEmits(['edit-click', 'delete-click', 'change-type']);

    const store = useSkillsStore();

    const isEdit = computed(() => {
        if (store.current) {
            return store.current.id === props.item.id;
        }

        return false;
    });
</script>

<style scoped lang="scss"></style>
