<template>
    <div class="app-page-content-intro-container" :class="responsiveClass">
        <div
            v-if="title"
            class="text-h4 font-weight-700 w-100 text-left"
            :class="!hasSlot('default') ? 'mb-10' : ''"
        >
            {{ title }}
        </div>
        <div v-else-if="hasSlot('title')" :class="!hasSlot('default') ? 'mb-10' : ''">
            <slot name="title" />
        </div>

        <div class="text-left mt-5 mb-10 w-100 text-paragraph-md" v-if="hasSlot('default')">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useSlots } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const emit = defineEmits(['show-tutorial']);

    withDefaults(
        defineProps<{
            title?: string;
        }>(),
        {
            title: '',
        },
    );

    const { responsiveClass } = useIsDesktop();

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .app-page-content-intro-container.desktop {
        position: sticky;
        top: 160px;
    }

    .app-page-content-intro-container.mobile {
        margin-top: 16px;
    }
</style>
