<template>
    <PlanPreviewLayout
        :to="navigateTo"
        class="fill-height"
        :image-src="imageSrc"
        title="My Work Plan"
        :submit-title="submitTitle"
    >
        <template v-slot:default>
            <div>
                <p class="mb-2">
                    Action creates motivation, not the other way around! We are keen to help you get
                    motivated to reach your full ambition, by helping you pull together an action
                    plan. Whether it be to just work differently with your colleagues, move roles
                    internally, or hatch out a whole new future for yourself - we’re here to help!
                </p>
                <p class="mb-2">
                    We will use the challenges you have identified in your canvas, and help you come
                    up with some realistic actions to solve these. And Alex is here to help - so
                    lets get started!
                </p>

                <v-banner
                    v-if="!canNavigateToPlan"
                    class="mt-4 font-weight-medium"
                    icon="mdi-chat-alert"
                    color="yellow"
                >
                    You haven’t completed your Current Challenges in your Canvas. Complete those
                    first, and then we can work on your Work Plan.
                </v-banner>
            </div>
        </template>
    </PlanPreviewLayout>
</template>

<script setup lang="ts">
    import imageSrc from '@/assets/images/canvas/preview/current-challenge.svg';
    import PlanPreviewLayout from '@/components/plan/preview/PlanPreviewLayout.vue';
    import { computed } from 'vue';
    import { currentChallengeRoute } from '@/router/routes';
    import { useCurrentPlanStore } from '@/store/plan/current/store';

    const store = useCurrentPlanStore();

    const canNavigateToPlan = computed(() => {
        return store.hasChallenges;
    });

    const navigateTo = computed(() => {
        return currentChallengeRoute;
    });

    const submitTitle = computed(() => {
        if (canNavigateToPlan.value) {
            return 'See my Work Plan';
        } else {
            return 'Set my current challenges';
        }
    });
</script>
