<template>
    <v-card elevation="0" class="text-custom-grey-900 w-100">
        <v-card-title class="text-heading-h4 font-weight-700 text-wrap px-0 py-2">
            Current employment
        </v-card-title>

        <v-card-text class="px-0 text-wrap text-paragraph-md" v-if="userInputs">
            <v-text-field
                label="Current Company"
                variant="outlined"
                v-model="userInputs.current_company"
                :rules="[requiredRule('Current Company')]"
                validate-on="input"
            />

            <v-text-field
                label="Job Title"
                variant="outlined"
                v-model="userInputs.job_title"
                :rules="[requiredRule('Job Title')]"
                validate-on="input"
            />
        </v-card-text>
    </v-card>
    <v-card elevation="0" class="text-custom-grey-900 w-100">
        <v-card-title class="text-heading-h4 font-weight-700 text-wrap px-0 py-2">
            Location & Residency
        </v-card-title>

        <v-autocomplete
            label="NZ Region"
            variant="outlined"
            :items="nzRegionOptions"
            :return-object="false"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            v-model="userInputs.location"
            :rules="[requiredRule('NZ Region')]"
            class="mb-2"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            class="mb-2"
            label="Visa Status"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :items="visaOptions"
            v-model="userInputs.visa_type"
            :rules="[requiredRule('Visa')]"
            :return-object="false"
            item-title="title"
            item-value="value"
            validate-on="input"
        />
    </v-card>

    <v-card elevation="0" class="text-custom-grey-900 w-100">
        <v-card-title class="text-heading-h4 font-weight-700 text-wrap px-0 py-2">
            Languages
        </v-card-title>

        <v-card-text class="px-0 text-wrap text-paragraph-md" v-if="userInputs">
            <v-autocomplete
                class="mb-2"
                label="Primary language"
                variant="outlined"
                :chips="true"
                :auto-select-first="true"
                :clearable="true"
                :items="primaryLanguages"
                v-model="userInputs.primary_language"
                :rules="[requiredRule('Primary language')]"
                :return-object="false"
                item-title="title"
                item-value="value"
                validate-on="input"
            />

            <v-autocomplete
                class="mb-2"
                label="Other languages"
                variant="outlined"
                :chips="true"
                :auto-select-first="true"
                :clearable="true"
                :multiple="true"
                :items="otherLanguages"
                v-model="userInputs.other_languages"
                :return-object="false"
                item-title="title"
                item-value="value"
            />
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import {
        familiarLanguageOptions,
        nzRegionOptions,
        otherLanguageOptions,
        visaOptions,
    } from '@/components/profile/userInputOptions';
    import { requiredRule } from '@/components/profile/rules';
    import { computed, watch } from 'vue';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);

    const primaryLanguages = computed(() => [...familiarLanguageOptions, ...otherLanguageOptions]);

    watch(
        () => userInputs.value.primary_language,
        (newValue) => {
            if (newValue && userInputs.value.other_languages?.includes(newValue)) {
                userInputs.value.other_languages = userInputs.value.other_languages.filter(
                    (language: string) => language !== newValue,
                );
            }
        },
    );

    const otherLanguages = computed(() => {
        const options = [...familiarLanguageOptions, ...otherLanguageOptions];

        if (userInputs.value.primary_language) {
            return options.filter((option) => option.value !== userInputs.value.primary_language);
        }

        return options;
    });
</script>
