import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export const useLoadingStore = defineStore('loading', () => {
    const title = ref<string | null>(null);
    const subtitle = ref<string | null>(null);

    const setLoadingText = (_title: string, _subtitle?: string) => {
        title.value = _title;

        if (_subtitle) {
            subtitle.value = _subtitle;
        }
    };

    const router = useRouter();
    // Register a beforeEach guard when the store initializes
    const initializeRouterGuard = () => {
        router.beforeEach(() => {
            clearLoadingText(); // Clear loading text before the route changes
            return true; // Allow the route change
        });
    };

    const clearLoadingText = () => {
        title.value = null;
        subtitle.value = null;
    };

    return { title, subtitle, setLoadingText, clearLoadingText, initializeRouterGuard };
});
