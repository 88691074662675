<template>
    <PersonalStrengthListItemForm v-if="isEdit" />
    <AppCard
        v-else
        class="bg-white border-2-grey-200 px-5 py-3"
        :editable="true"
        :removable="false"
        remove-icon="mdi-delete"
        @edit-click="onEnableEditClick"
    >
        <template v-slot:title>
            <v-row class="d-flex mr-5 align-center" no-gutters>
                <v-col
                    :cols="isDesktop ? 12 : 12"
                    class="px-0 text-paragraph-lg font-weight-700"
                    @click="onEnableEditClick"
                >
                    {{ item.description }}
                </v-col>
            </v-row>
        </template>
    </AppCard>
</template>
_skills_of_type

<script setup lang="ts">
    import AppCard from '@/components/common/cards/AppCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed } from 'vue';
    import { type CanvasSkill } from '@/api/types/canvas/skills';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import PersonalStrengthListItemForm from '@/components/canvas/personal-strengths/PersonalStrengthListItemForm.vue';

    const props = defineProps<{
        item: CanvasSkill;
    }>();

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['edit-click', 'delete-click', 'change-type']);

    const store = usePersonalStrengthsStore();

    const onEnableEditClick = () => {
        store.setEdit(props.item);
    };

    const onDelete = async () => {
        await store.removePersonalStrength(props.item);
    };

    const isEdit = computed(() => {
        if (store.current) {
            return store.current.id === props.item.id;
        }

        return false;
    });
</script>

<style scoped lang="scss"></style>
