import type { InlineGuideMessage } from '@/api/types/inlineGuideMessage';
import {
    getInlineGuidePersonalStrengths,
    getInlineGuideSkills,
    getInlineGuideStory
} from '@/services/inline-guide/api';
import { fetch, fetchAllowNull } from '@/services/base';
import { InlineGuideSection } from '@/store/inline-guide/types';

export async function fetchInlineGuide(
    canvasId: number,
    section: InlineGuideSection,
    accessToken: string,
): Promise<InlineGuideMessage | null> {
    switch (section) {
        case InlineGuideSection.Story:
            return fetch(
                'canvas-inline-guide-story',
                async () => await getInlineGuideStory(canvasId, accessToken),
            );
        case InlineGuideSection.Skills:
            return fetchAllowNull(
                'canvas-inline-guide-skills',
                async () => await getInlineGuideSkills(canvasId, accessToken),
            );
        case InlineGuideSection.PersonalStrength:
            return fetchAllowNull(
                'canvas-inline-guide-personal-strengths',
                async () => await getInlineGuidePersonalStrengths(canvasId, accessToken),
            );
        default:
            throw new Error(`Unknown section ${section}`);
    }
}
