import { defineStore } from 'pinia';
import { ref } from 'vue';

/**
 * UI Story Store
 *
 * A dedicated Pinia store for managing the expanded state of specific sections
 * in the application. This store allows you to track whether the "Who I Am"
 * and "What I Am Working On" sections are expanded, and provides methods to
 * update or reset these states independently.
 *
 * This store is different from the useStoryStore store, which manages the data of the story.
 * This store is only responsible for managing the expanded state of the sections. The intention
 * is to separate concerns and keep the store focused on a single responsibility. Mostly, because
 * during lifecycle of the session, we want to remember the expanded state of the sections.
 * Managing this in the useStoryStore would mean we need to partially update the story state,
 * and starts getting more complex
 *
 * Note: The whatIOffer section is not included in this store because it is always expanded.
 */

export const useUIStoryStore = defineStore('section-state-store', () => {
    const whoIAmSectionExpanded = ref(false);
    const whatIAmWorkingOnSectionExpanded = ref(false);

    function expandWhoIAm(): void {
        whoIAmSectionExpanded.value = true;
    }

    function expandWhatIAmWorkingOn(): void {
        whatIAmWorkingOnSectionExpanded.value = true;
    }

    // Expose state, getters, and actions
    return {
        whoIAmSectionExpanded,
        expandWhoIAm,
        whatIAmWorkingOnSectionExpanded,
        expandWhatIAmWorkingOn,
    };
});
