<template>
    <v-card
        class="rounded-8"
        elevation="0"
        :disabled="disabled"
        variant="outlined"
        :class="responsiveClass"
    >
        <v-card-item class="pa-0" v-if="title || hasSlot('title')">
            <span class="text-paragraph-lg font-weight-600" v-if="title">{{ title }}</span>
            <slot v-if="hasSlot('title')" name="title" />
            <template v-slot:append>
                <v-icon
                    v-if="editable"
                    class="mr-2"
                    :disabled="isSaving"
                    @click="emit('edit-click')"
                    >mdi-pencil
                </v-icon>
                <v-icon
                    v-if="submit"
                    class="mr-2"
                    :disabled="isSaving"
                    @click="emit('submit-click')"
                    >mdi-check
                </v-icon>
                <v-icon v-if="removable" :disabled="isSaving" @click="emit('delete-click')">{{
                    removeIcon
                }}</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="mt-2 pa-0 text-paragraph-sm" v-if="description || hasSlot('default')">
            <span v-if="description">{{ description }}</span>
            <slot v-if="hasSlot('default')" />
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref, useSlots } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const props = withDefaults(
        defineProps<{
            color?: string;
            title?: string;

            description?: string;
            editable?: boolean;
            submit?: boolean;
            disabled?: boolean;
            isSaving?: boolean;
            removable?: boolean;
            removeIcon?: string;
        }>(),
        {
            color: 'white',
            editable: true,
            isSaving: false,
            submit: false,
            removable: true,
            removeIcon: 'mdi-delete',
        },
    );

    const emit = defineEmits(['edit-click', 'submit-click', 'delete-click']);

    const { responsiveClass } = useIsDesktop();

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .card-title-append {
        position: absolute;
        right: 20px;
    }

    .v-card.mobile {
        :deep(.v-card-item__append) {
            align-self: baseline;
        }
    }

    .select-column {
        min-width: fit-content;
        max-width: 40px;
    }
</style>
