<template>
    <div v-if="maybeFilterByAction && inlineGuideStore.hasMessage">
        <UploadCurriculumGuideInline
            v-if="
                actionKey === InlineGuideActionName.UploadCv ||
                actionKey === InlineGuideActionName.ReTryUploadCv
            "
            @after-upload="click"
            :title="inlineGuideStore.title"
            :text="inlineGuideStore.content"
        />
        <app-guide-inline
            v-else
            :title="inlineGuideStore.title"
            :text="inlineGuideStore.content"
            @close="clear"
            @submit="click"
            :action-text="actionLabel"
        />
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, watch } from 'vue';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { useRouter } from 'vue-router';
    import {
        careerHistoryRoute,
        currentChallengeRoute,
        futureAspirationRoute,
        personalityTypeRoute,
        personalStrengthRoute,
        personalValuesRoute,
        skillsRoute,
    } from '@/router/routes';
    import {
        InlineGuideActionName,
        InlineGuideSection,
    } from '@/store/inline-guide/types';
    import UploadCurriculumGuideInline from '@/components/canvas/learned-experiences/UploadCurriculumGuideInline.vue';
    import type { InlineGuideActionMap } from '@/components/inline-guide/types';
    import { useSkillsStore } from '@/store/skills/store';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';

    const router = useRouter();

    const props = withDefaults(
        defineProps<{
            /** Only show the guide for these actions (if provided). */
            only?: InlineGuideActionName[];
            section: InlineGuideSection;
        }>(),
        {
            only: undefined,
            section: InlineGuideSection.Story,
        },
    );

    const maybeFilterByAction = computed(() => {
        if (!props.only) return true;

        // If there is no action, we should not show the guide,
        // given the user of this component asked to filter only certain actions
        if (!actionKey.value) return false;
        return props.only.includes(actionKey.value);
    });

    const inlineGuideStore = useInlineGuideStore();
    onMounted(async () => {
        await inlineGuideStore.getMessage(props.section);
    });

    const clear = () => {
        inlineGuideStore.removeMessage();
    };

    const actionMap: InlineGuideActionMap = {
        //
        // Void actions
        //
        [InlineGuideActionName.ActivitiesComplete]: {
            type: 'void',
            label: 'Dismiss',
        },
        //
        // CV related actions with callbacks
        //
        [InlineGuideActionName.UploadCv]: {
            type: 'callback',
            callback: async () => {
                await inlineGuideStore.getMessage(props.section);
            },
            label: 'Dismiss',
        },
        [InlineGuideActionName.ReTryUploadCv]: {
            type: 'callback',
            callback: async () => {
                await inlineGuideStore.getMessage(props.section);
            },
            label: 'Dismiss',
        },
        [InlineGuideActionName.PersonalStrengthsFoundInferred]: {
            type: 'immediate-callback',
            immediateCallback: async () => {
                await usePersonalStrengthsStore().syncInferredSkills();
            },
            label: 'Close',
        },
        [InlineGuideActionName.SkillsFoundInferred]: {
            type: 'immediate-callback',
            immediateCallback: async () => {
                await useSkillsStore().syncInferredSkills();
            },
            label: 'Close',
        },
        //
        // Navigation related actions
        //
        [InlineGuideActionName.GotoCareerHistory]: {
            type: 'navigation',
            route: careerHistoryRoute,
            label: 'Go to my Career History',
        },
        [InlineGuideActionName.GotoValues]: {
            type: 'navigation',
            route: personalValuesRoute,
            label: 'Go to my Values',
        },
        [InlineGuideActionName.GotoPersonalityType]: {
            type: 'navigation',
            route: personalityTypeRoute,
            label: 'Go to my Personality Type',
        },
        [InlineGuideActionName.GotoMemorableExperiences]: {
            type: 'navigation',
            route: careerHistoryRoute,
            label: 'Go to my Memorable Experiences',
        },
        [InlineGuideActionName.GotoSkills]: {
            type: 'navigation',
            route: skillsRoute,
            label: 'Go to my Skills',
        },
        [InlineGuideActionName.GotoPersonalStrength]: {
            type: 'navigation',
            route: personalStrengthRoute,
            label: 'Go to my personal strengths',
        },
        [InlineGuideActionName.GotoCurrentChallenges]: {
            type: 'navigation',
            route: currentChallengeRoute,
            label: 'Go to my Current Challenges',
        },
        [InlineGuideActionName.GotoFutureAspiration]: {
            type: 'navigation',
            route: futureAspirationRoute,
            label: 'Go to my Future Aspiration',
        },
    } as const;

    const actionKey = computed(() => inlineGuideStore.action ?? null);

    const click = async () => {
        const value = action.value;
        if (!value) {
            clear();
            return;
        }

        if (value.type === 'void' || value.type === 'immediate-callback') {
            clear();
        } else if (value.type === 'callback') {
            clear();
            await value.callback();
        } else if (value.type === 'navigation') {
            clear();
            await router.push(value.route);
        } else {
            console.error('Unknown action type');
        }
    };

    const action = computed(() => {
        return actionKey.value ? actionMap[actionKey.value] : null;
    });

    watch(action, async (currentAction) => {
        if (currentAction?.type === 'immediate-callback') {
            console.log(`Executing immediate callback for ${actionKey.value}`);
            await currentAction.immediateCallback();
        }
    });

    const actionLabel = computed(() => action.value?.label || 'Close');
</script>
