<template>
    <app-page>
        <app-page-header title="Challenges" @show-tutorial="onShowTutorial" />
        <CurrentChallengesTutorial v-model="showTutorial" :navigate-back="tutorialNavigateBack" />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="What is currently challenging you?">
                    <p class="text-paragraph-md mb-2">
                        From our research, we know people like you experience many similar
                        challenges. These challenges can hold you back from achieving your
                        performance goals or targets.
                    </p>
                    <p class="text-paragraph-md">
                        We've listed some of the most common here for you. Feel free to add your
                        own, and select as many as you'd like to work on.
                    </p>
                </app-page-content-intro>
            </template>
            <CurrentChallengesContent />
        </app-page-content>

        <app-page-footer>
            <template v-slot:errors>
                <SubmitNotification
                    :store="store"
                    :activity-area="UserActivityArea.CurrentChallenges"
                />
            </template>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions
                    @on-submit="onSaveClick"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty"
                />
                <WarningChangesWillBeLost
                    :value="store.isDirty"
                    @on-discard-changes="onDiscardChanges"
                    @on-submit="onSaveClick"
                />
            </template>
        </app-page-footer>

        <CurrentChallengesGuide v-if="store.isLoaded" />
        <PageActivity :area="UserActivityArea.CurrentChallenges" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useCurrentChallengeStore } from '@/store/current-challenges/store';
    import CurrentChallengesContent from '@/components/canvas/current-challenges/CurrentChallengesContent.vue';
    import CurrentChallengesGuide from '@/components/canvas/current-challenges/CurrentChallengesGuide.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { storyRoute } from '@/router/routes';
    import { useRouter } from 'vue-router';
    import CurrentChallengesTutorial from '@/components/canvas/current-challenges/CurrentChallengesTutorial.vue';
    import { useTutorial } from '@/store/useTutorial';

    const router = useRouter();
    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = useCurrentChallengeStore();

    await canvasStore.load();
    await store.load();

    onMounted(async () => {
        await activityStore.load();
        await activityStore.addEntryActivity(UserActivityArea.CurrentChallenges);
    });

    const onDiscardChanges = () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const onSaveClick = async () => {
        await store.saveProgress();

        if (!store.isError) {
            await router.push(storyRoute);
        }
    };

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.CurrentChallenges);
    });
</script>
