<template>
    <v-col :cols="isDesktop ? 6 : 12">
        <v-card class="text-left text-heading-h3 pa-0 mb-5" variant="flat" color="">
            {{ props.title }}
            ({{ sortedSelectedItems.length }}/{{ CORE_OR_GROWTH_AMOUNT_REQUIRED }})
        </v-card>
        <v-row class="d-flex flex-column" no-gutters>
            <draggable
                v-model="draggableList"
                group="group"
                :disabled="
                    draggingUnselected &&
                    sortedSelectedItems.length === CORE_OR_GROWTH_AMOUNT_REQUIRED
                "
                item-key="personal_value_id"
                class="list-group"
                ghost-class="ghost"
                handle=".value-card-handle"
                @start="draggingSelected = true"
                @end="draggingSelected = false"
            >
                <template #item="{ element, index }">
                    <PersonalValueSubTypeChip
                        v-if="element.itemType === DraggableListItemType.Item"
                        :sub-type="subType"
                        class="table-cell"
                        :selection="element"
                        :class="{
                            'first-row': index === 0,
                            'last-row': index === draggableList.length - 1,
                        }"
                        :key="`sub-type-selected-${element.personalValue?.id}`"
                    />
                    <PersonalValuePlaceholderListItem
                        v-else-if="element.itemType === DraggableListItemType.Placeholder"
                        :class="{
                            'first-row': index === 0,
                            'last-row': index === draggableList.length - 1,
                        }"
                        :key="`placeholder-${index}`"
                        class="w-100 table-cell"
                    />
                </template>
            </draggable>

            <v-card
                height="80"
                color="secondary-orange"
                class="my-4 border-dashed cols-12 bg-custom-grey-200 w-100 not-draggable pa-6"
                data-draggable="false"
                v-if="unselectedDraggableList.length"
            >
                <p class="w-100 text-use-heading-font text-center font-weight-medium not-draggable">
                    Select a max of {{ CORE_OR_GROWTH_AMOUNT_REQUIRED }}
                </p>
            </v-card>

            <draggable
                v-model="unselectedDraggableList"
                group="group"
                :disabled="false"
                item-key="personal_value_id"
                class="list-group "
                ghost-class="ghost"
                @start="draggingUnselected = true"
                @end="draggingUnselected = false"
            >
                <template #item="{ element, index }">
                    <PersonalValueSubTypeChip
                        class="table-cell"
                        :class="{
                            'first-row': index === 0,
                            'last-row': index === unselectedDraggableList.length - 1,
                        }"
                        v-if="element.itemType === DraggableListItemType.Item"
                        :sub-type="subType"
                        :selection="element"
                        :key="`sub-type-unselected-${element.personalValue?.id}`"
                    />
                </template>
            </draggable>
        </v-row>
    </v-col>
</template>
<script setup lang="ts">
    import draggable from 'vuedraggable';
    import {
        CORE_OR_GROWTH_AMOUNT_REQUIRED,
        usePersonalValuesStore,
    } from '@/store/personal-values/store';
    import type { PersonalValueSelection } from '@/store/personal-values/types';
    import { computed, ref } from 'vue';
    import PersonalValueSubTypeChip from '@/components/canvas/personal-values/upload/second-step/PersonalValueSubTypeSelectedItem.vue';
    import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';
    import {
        type DraggableListItem,
        DraggableListItemType,
        makeListItem,
        makePlaceholderItem,
    } from '@/components/canvas/personal-values/upload/second-step/draggableListUtils';
    import { range } from 'lodash';
    import PersonalValuePlaceholderListItem from '@/components/canvas/personal-values/upload/second-step/PersonalValuePlaceholderListItem.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const draggingSelected = ref(false);
    const draggingUnselected = ref(false);

    const store = usePersonalValuesStore();

    const { isDesktop } = useIsDesktop();

    const props = defineProps<{
        title: string;
        subType: PersonalValueSelectionSubType;
        items: PersonalValueSelection[];
    }>();

    /**
     * The draggable list is a computed property with a getter and a setter.
     * The getter allows us the list of items including the display the max alert indication
     * The setter part allows us to set in the pinia store only the information
     * we need (removing the max alert indication)
     *
     * @see https://stackoverflow.com/a/74952600
     */
    const draggableList = computed({
        get: () => {
            const selected = sortedSelectedItems.value;
            const placeholderItems = range(0, placeholderAmount.value).map(makePlaceholderItem);

            return [...selected.map(makeListItem), ...placeholderItems];
        },
        set: (orderedList: DraggableListItem[]) => {
            const items = makeListToUpdate(orderedList);
            store.updateSubTypeList(items, props.subType, true);
        },
    });

    function makeListToUpdate(orderedList: DraggableListItem[]): PersonalValueSelection[] {
        return orderedList.filter((item, index) => {
            return item.itemType === DraggableListItemType.Item;
        }) as PersonalValueSelection[];
    }

    const unselectedDraggableList = computed({
        get: () => {
            const unselected = unselectedItems.value;
            return [...unselected.map(makeListItem)];
        },
        set: (orderedList: DraggableListItem[]) => {
            const items = makeListToUpdate(orderedList);
            store.updateSubTypeList(items, props.subType, false);
        },
    });

    const placeholderAmount = computed(() => {
        const amount = CORE_OR_GROWTH_AMOUNT_REQUIRED - selectedItems.value.length;
        return amount >= 0 ? amount : 0;
    });

    const selectedItems = computed(() => {
        return props.items.filter((item) => {
            return item.selected;
        });
    });

    const unselectedItems = computed(() => {
        return props.items.filter((item) => {
            return !item.selected;
        });
    });

    const byOrderComparator = (a: PersonalValueSelection, b: PersonalValueSelection) => {
        // equal items sort equally
        if (a.order === b.order) {
            return 0;
        }

        if (a.order === undefined) {
            return 1;
        }
        if (b.order === undefined) {
            return -1;
        }

        return a.order < b.order ? -1 : 1;
    };

    const sortedSelectedItems = computed(() => {
        return selectedItems.value.sort(byOrderComparator);
    });
</script>

<style scoped lang="scss">
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }

    .table-cell {
        --border-color: rgb(var(--v-theme-custom-neutral-grey-200));
        --cell-border: 2px solid var(--border-color);

        border-top-color: var(--cell-border);
        border-left-color: var(--cell-border);
        border-right-color: var(--cell-border);
        border-bottom: 0;

        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
        padding-left: 0px;


        /* Add rounded corners to the first row */
        &.first-row {
            border-color: var(--border-color) !important;
            border-radius: 4px 4px 0 0;
        }

        /* Add rounded corners to the last row */
        &.last-row {
            border-color: var(--border-color) !important;
            border-radius: 0 0 4px 4px;
            border-bottom: var(--cell-border);
        }

        &.first-row.last-row {
            border-radius: 4px;
        }

        /* Ensure color is applied to the middle rows */
        &:not(.first-row):not(.last-row) {
            border-radius: 0;
            border-color: var(--border-color) !important;
        }
    }

</style>
