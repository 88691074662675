<template>
    <app-page>
        <app-page-header title="Skills" @show-tutorial="onShowTutorial" />
        <SkillsGuideModalExplanation v-model="showTutorial" :navigate-back="tutorialNavigateBack" />
        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Identify your different skills">
                    <p class="text-left mt-5 w-100 text-paragraph-md">
                        To complete this activity, you’ll need to add at least three learned skills,
                        and one technical skill. Ideally, aim to add at least ten skills overall.
                    </p>

                    <v-divider class="my-5" />

                    <app-label :type="SkillType.Learned" class="mb-2" />
                    <div class="text-paragraph-sm">
                        These are skills you've learned from experience, and normally don't require
                        formal training. You will have learned these on the job or through working
                        groups.. They are professional skills such as negotiation, collaboration or
                        rostering.
                    </div>
                    <app-label :type="SkillType.Technical" class="mt-5 mb-2" />
                    <div class="text-paragraph-sm">
                        These are skills that you will have trained in or become certified in and
                        can evidence some current qualifications eg accounting, law or engineering.
                        These are normally baseline requirements for certain jobs, so it’s important
                        to record this accurately.
                    </div>
                </app-page-content-intro>
            </template>

            <SkillsContent />
        </app-page-content>

        <SkillsGuide />

        <app-page-footer>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions :dummy="true" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.Skills" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useSkillsStore } from '@/store/skills/store';
    import SkillsContent from '@/components/canvas/skills/SkillsContent.vue';
    import SkillsGuide from '@/components/canvas/skills/SkillsGuide.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import SkillsGuideModalExplanation from '@/components/canvas/skills/guide-modal/SkillsGuideModalExplanation.vue';
    import { SkillType } from '@/store/skills/types';
    import { useCurriculumSubscribeChangesStore } from '@/store/syncing/curriculumSyncingStore';
    import { InlineGuideSection } from '@/store/inline-guide/types';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { useTutorial } from '@/store/useTutorial';

    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = useSkillsStore();

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    const curriculumPollingStore = useCurriculumSubscribeChangesStore();

    await activityStore.addEntryActivity(UserActivityArea.Skills);

    // Subscribe to uploads on CV, and pull inferred skills and messages
    const currentInferredSkills = store.allInferredSkills.length;

    onMounted(() => {
        if (!store.confirmedSkills.length) {
            showTutorial.value = true;
        }

        curriculumPollingStore.addSubscription('inferred-skills', {
            fn: subscription,
            onComplete: async () => {
                await useInlineGuideStore().getMessage(InlineGuideSection.Skills);
            },
        });
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Skills);

        curriculumPollingStore.removeSubscription('inferred-skills');
    });

    const subscription = async () => {
        const newInferredSkillsLength = store.allInferredSkills.length;
        console.info(
            'Running Skill subscription to curriculum changes.\n' +
                `Current amount of inferred skills: ${currentInferredSkills}. New skills ${newInferredSkillsLength}`,
        );

        if (newInferredSkillsLength > currentInferredSkills) {
            // THIS IS A LOT OF COUPLING WITH KNOWLEDGE OF THE APP. WE NEED A BETTER MECHANISM
            // Execute only once at the end of the subscription
            console.info(
                `New inferred skills. Exiting subscription successfully. Fetching context skills inline message...`,
            );
            return false;
        }

        console.info(`Fetching inferred skills inside subscription...`);
        await store.syncInferredSkills();

        return true;
    };
</script>
