import { asyncTimeout } from '@/lib';
import type { AxiosRequestConfig } from 'axios';
import { throwIfAborted } from '@/lib/genericAbortSignal';

// const log = taggedLogger('poll-challenge');

/** 5 minute timeout */
const DEFAULT_POLL_TIMEOUT_MILLISECONDS = 60 * 1000 * 5;

/** Poll every second */
const POLL_INTERVAL_MILLISECONDS = 10000 as const;

/** Log every 5th attempt at info level */
const LOG_ATTEMPT_AT_INFO = 5 as const;

export class TimeoutError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, TimeoutError.prototype);
    }
}

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 *
 * @throws TimeoutError if the timeout elapses.
 */
export async function executeUntil(
    onEach: () => Promise<boolean>,
    config: AxiosRequestConfig & { subscriptionDuration?: number },
): Promise<void> {
    // Note: we were previously checking whether the case had a valid template link in this function
    // We may have to do that again

    // Calculate the time we need to time-out
    const timeout = config?.subscriptionDuration ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS;
    const timeoutTime = Date.now() + timeout;

    for (let attempt = 1; ; ++attempt) {
        throwIfAborted(config?.signal);

        // Log every Nth attempt at info level
        // const logLevel = attempt % LOG_ATTEMPT_AT_INFO === 0 ? 'info' : 'debug';
        console.info(`Execute ${attempt} round of subscription to user changes...`);

        //
        // TODO: If the curriculum had a state, we could fetch until a condition is met, instead of 1 minute.
        //
        // Attempt to fetch resource
        const resource = await onEach();
        if (resource) {
            if (attempt > 1) {
                console.info('Stop subscriptions after %d attempts. All stopped', attempt);
            }
        }
        throwIfAborted(config?.signal);

        if (Date.now() >= timeoutTime) {
            throw new TimeoutError('Timed for subscription arrived to an end. This is expected.');
        }

        await asyncTimeout(POLL_INTERVAL_MILLISECONDS, config?.signal);
    }
}
